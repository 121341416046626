var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
var _log = false;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var MultiPinMapNumberedPins = /** @class */ (function (_super) {
            __extends(MultiPinMapNumberedPins, _super);

            MultiPinMapNumberedPins.map = null;
            MultiPinMapNumberedPins.KeyHtmlPushpin = 'htmlpushpin';

            if(_log) console.log('wdot.maps.MultiPinMapNumberedPins()');

            function MultiPinMapNumberedPins(key) {
                var _this = _super.call(this, key) || this;
                _this.centerPinLocation = null;
                _this.center = null;
                _this.eventLocations = null;
                _this.directionsManager = null;
                _this.directionsErrorEventObj = null;
                return _this;
            }
            MultiPinMapNumberedPins.displayInfobox = function (pin, location, showInfoBox, map) {
                if(_log) console.log('MultiPinMapNumberedPins')
                if (pin) {
                    //CUSTOM HTML PUSHPIN WITH DRAGGABLE
                    //https://github.com/microsoft/BingMapsV8CodeSamples/blob/main/Samples/Overlays/Html%20Pushpin%20Layer/Html%20Pushpin%20Layer.html

                    //Register the custom module.
                    Microsoft.Maps.registerModule('HtmlPushpinLayerModule', '/JS/wdot/bingmaps/HtmlPushpinLayerModule_v2.js');

                    var fullAddress = location.Address.Street + " " + location.Address.City + ", " + location.Address.State + " " + location.Address.Zip;
                    Wdot.Maps.MultiPinMapNumberedPins.closeInfobox();

                    //original regular infobox
                    // var infoboxOptions = {
                    //     htmlContent: Maps.BingMapsHelpers.getInfoboxHtmlString(location.Name, fullAddress, fullAddress, location.WebsiteUrl, true, -50),
                    //     visible: showInfoBox,
                    //     offset: new Microsoft.Maps.Point(0, -28)
                    // };
                    // var infoBox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(location.Latitude, location.Longitude), infoboxOptions);
                    // Wdot.Maps.MultiPinMapNumberedPins.infoBox = infoBox;
                    // Wdot.Maps.MultiPinMapNumberedPins.infoBox.setMap(map);

                    //New HTML Pushpin styled after an Infobox
                    //Load the module.
                    Microsoft.Maps.loadModule('HtmlPushpinLayerModule', function () {

                        //Create a simple reusable HTML template to keep things easy.
                        var htmlTemplate = '<div class="js-htmlpushpin" style="color:red;background-color:white;width:100px;padding:2px;text-align:center; cursor: grab;">{text}</div>';
                        
                        var pinsHtml = [
                            //sample code pushpin
                            //new HtmlPushpin(new Microsoft.Maps.Location(location.Latitude + 0.001, location.Longitude + 0.01), htmlTemplate.replace('{text}', 'Draggable'), { anchor: new Microsoft.Maps.Point(50, 12), draggable: 
                            new HtmlPushpin(new Microsoft.Maps.Location(location.Latitude, location.Longitude), Maps.BingMapsHelpers.getInfoboxHtmlString(location.Name, fullAddress, fullAddress, location.WebsiteUrl, true, -50, 'c-grab pointer-events-all'), { anchor: new Microsoft.Maps.Point(20, 165), draggable: true, metaData: {type: MultiPinMapNumberedPins.KeyHtmlPushpin} })
                        ];
                        var layer = new HtmlPushpinLayer(pinsHtml);

                        //Add the HTML pushpin to the map.
                        map.layers.insert(layer);

                        Wdot.Maps.MultiPinMapNumberedPins.layer = layer;

                        MultiPinMapNumberedPins.map = map;
                        //console.log('MultiPinMapNumberedPins.map:', MultiPinMapNumberedPins.map);

                        //for testing purposes only
                        // setTimeout(() => {
                        //     MultiPinMapNumberedPins.removeHtmlPushpinLayer();
                        // }, 2000);

                    });


                }
            };
            MultiPinMapNumberedPins.prototype.loadMap = function (locationsArray, zoomLevel, mapElement, eventLocations, pinWithInfoBoxLocation) {
                if(_log) console.group('wdot/bingmaps/MultiPinMapNumberedPins.js loadMap');
                if(_log) console.log('locationsArray:', locationsArray);
                if(_log) console.log('zoomLevel:', zoomLevel);
                if(_log) console.log('mapElement:', mapElement);
                if(_log) console.log('eventLocations:', eventLocations);
                if(_log) console.log('pinWithInfoBoxLocation:', pinWithInfoBoxLocation);

                this.eventLocations = eventLocations;
                this.pinLocationsArray = locationsArray;
                var self = this;
                if (mapElement !== undefined && mapElement != null) {
                    this.mapElement = mapElement;
                }
                else {
                    this.mapElement = document.getElementById('multiPinMap');
                }
                var latitude = 44.832017349893526, longitude = -89.84687250000002, center = new Microsoft.Maps.Location(latitude, longitude);
                this.map = new Microsoft.Maps.Map(this.mapElement, {
                    enableClickableLogo: false,
                    showMapTypeSelector: false,
                    showDashboard: true,
                    showBreadcrumb: false,
                    credentials: this.apiKey,
                    zoom: zoomLevel,//5,
                    center: center,
                    disableScrollWheelZoom: true,
                    showZoomButtons: this.showZoomControls
                });
                this.map.getZoomRange = function () {
                    return { max: 15, min: 5 };
                };
                if (pinWithInfoBoxLocation) {
                    this.centerPinLocation = new Microsoft.Maps.Location(pinWithInfoBoxLocation.Latitude, pinWithInfoBoxLocation.Longitude);
                    try {
                        if (this.eventLocations.length <= 1) {
                            this.addLocationWithInfoBox(pinWithInfoBoxLocation, true);
                        }
                        else {
                            this.displayEventsWithMultipleLocations(this.eventLocations);
                        }
                    }
                    catch (e) {
                        this.addLocationWithInfoBox(pinWithInfoBoxLocation, true);
                    }
                }
                var x = 0;
                ko.utils.arrayForEach(this.pinLocationsArray, function (item) {
                    item.PinText = x + 1;
                    self.addLocation(item);
                    x++;
                });
                if(_log) console.log('map.x:', x);
                if (zoomLevel != 'undefined' && zoomLevel != null && x >= 1) {//was x==1
                    var center_1 = new Microsoft.Maps.Location(locationsArray[0].Latitude, locationsArray[0].Longitude);
                    this.map.setView({
                        center: center_1,
                        zoom: zoomLevel
                    });
                    if(_log) console.log('map.setView.zoomLevel:', zoomLevel);
                    //if(_log) alert('wdot/bingmaps/MultiPinMapNumberedPins.js foundzoomlevel and X zoomLevel:' + zoomLevel);
                }
                else {
                    this.bestMapView($(this.mapElement).find(".MicrosoftMap").width(), $(this.mapElement).find(".MicrosoftMap").height(), 175);
                    if(_log) console.log('map.bestMapView()');
                }

                if(_log) console.groupEnd(); 

            };
            MultiPinMapNumberedPins.prototype.addLocation = function (item) {
                var pinOptions = this.pinOptionsFactory.getPinOptions(Wdot.Enums.PushPinOptionsEnum.PinImageWithTextListingPage);
                var pin = _super.prototype.addLocation.call(this, item, pinOptions);
                if (pin != null) {
                    var self_1 = this;
                    pin.setOptions({ text: item.PinText.toString() });
                    Microsoft.Maps.Events.addHandler(pin, 'click', function () {
                        //console.log('MultiPinMapNumberedPins.addLocation.pin.click.item:', item);
                        var jumpAnchor = $(self_1.mapElement).data('jump-anchor');
                        //console.log('MultiPinMapNumberedPins.addLocation.pin.click.jumpAnchor:', jumpAnchor);
                        if (jumpAnchor) {
                            // It seems there is a bug in Bing Maps that occurs the first time a user clicks on a pin where, if the scroll position is changed
                            // due to change in href or changing scroll position on window, after it scrolls to the intended position, Bing Maps will then reposition
                            // the user at the wrong place on the page in both Edge and Chrome. I posted in the Bing Maps forum and a MS employee could not explain
                            // the issue, but recommended this hacky workaround to add a timeout before changing scroll position.
                            setTimeout(function () {
                                window.scrollTo(null, $("#" + jumpAnchor).offset().top);

                                var infoId = document.getElementById("info" + item.PinText.toString());
                                if(infoId) {
                                    //console.log('found.infoId:', infoId);
                                    infoId.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                                }
                            }, 0);
                        }
                        else {
                            return true;
                        }
                    });
                }
                return pin;
            };
            MultiPinMapNumberedPins.prototype.displayInfobox = function (pin, location, showInfoBox) {
                Wdot.Maps.MultiPinMapNumberedPins.displayInfobox(pin, location, showInfoBox, this.map);
            };
            MultiPinMapNumberedPins.prototype.addLocationWithInfoBox = function (location, showInfoBox) {
                //console.log('addLocationWithInfoBox');
                var baseIcon = '//ecn.dev.virtualearth.net/mapcontrol/v7.0/7.0.20160525132934.57/i/BingTheme/pins/pin_55light.png';
                var hoverIcon = '//ecn.dev.virtualearth.net/mapcontrol/v7.0/7.0.20160525132934.57/i/BingTheme/pins/pin_55light_h.png';
                var offset = new Microsoft.Maps.Point(0, 5), pinOptions = {
                    visible: true,
                    textOffset: offset,
                    draggable: false,
                    anchor: new Microsoft.Maps.Point(14, 14),
                    icon: baseIcon,
                };
                var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(location.Latitude, location.Longitude), pinOptions);
                this.map.entities.push(pin);
                if (showInfoBox) {
                    this.displayInfobox(pin, location, showInfoBox);
                }
                var self = this;
                Microsoft.Maps.Events.addHandler(pin, 'click', (function () { return self.displayInfobox(pin, location, true); }));
            };
            MultiPinMapNumberedPins.prototype.displayEventsWithMultipleLocations = function (eventLocations) {
                var self = this;
                var showInfobox = true;
                ko.utils.arrayForEach(eventLocations, function (item) {
                    self.addLocationWithInfoBox(item, showInfobox);
                    if (showInfobox) {
                        showInfobox = false;
                    }
                });
            };
            MultiPinMapNumberedPins.infoBox = null;
            //Static methods
            MultiPinMapNumberedPins.closeInfobox = function () {
                if (this.infoBox) {
                    this.infoBox.setOptions({ visible: false });
                } else {
                    MultiPinMapNumberedPins.removeHtmlPushpinLayer();
                }
            };


            /**
             * Remove HTMLPushpinLayer from map, if found
            */
            MultiPinMapNumberedPins.removeHtmlPushpinLayer = function () {
                //console.log('removeHtmlPushpinLayer MultiPinMapNumberedPins.map:', MultiPinMapNumberedPins.map);

                if(MultiPinMapNumberedPins.map != null && MultiPinMapNumberedPins.map['layers'] != null) {
                    //console.log('MultiPinMapNumberedPins.map[layers]:', MultiPinMapNumberedPins.map['layers']);
                    //if map['layers'] found, loop through object and look for the layers named as Numbers, those are real layers.
                    for (const key in MultiPinMapNumberedPins.map['layers']) {
                        //console.log(`${key}`, map['layers'][key]);
                        if(!isNaN(key)) {
                            //console.log(`${key}`, MultiPinMapNumberedPins.map['layers'][key]);
                            var _pushpins = MultiPinMapNumberedPins.map['layers'][key]['_pushpins'];
                            //console.log('_pushpins:', _pushpins);

                            var isHtmlPushpin = false;

                            _pushpins.forEach(element => {
                                //console.log('element:', element);
                                if(element.metadata.type == MultiPinMapNumberedPins.KeyHtmlPushpin) {
                                    isHtmlPushpin = true;
                                }
                            });
                            //console.log('isHtmlPushpin:', isHtmlPushpin);

                            if(isHtmlPushpin) {
                                //found HtmlPushpin layer
                                MultiPinMapNumberedPins.map.layers.removeAt(key);
                            }

                        }
                    }
                }
            };
            return MultiPinMapNumberedPins;
        }(Maps.MultiPinMap));
        Maps.MultiPinMapNumberedPins = MultiPinMapNumberedPins;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));