var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var Widgets;
    (function (Widgets) {
        var TinyMceAutoComplete = (function (_super) {
            __extends(TinyMceAutoComplete, _super);
            function TinyMceAutoComplete() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            TinyMceAutoComplete.prototype.loadAutoComplete = function (selector, url) {
                var cache = {};
                var lastXhr;
                var itemSelected = false;
                var ajax = new Wdot.Ajax();
                //let autoCompeteHelper = new AutocompleteHelper();
                $(selector).autocomplete({
                    source: function (request, response) {
                        itemSelected = false;
                        response([{ Id: "AJAX", Name: '' }]);
                        ajax.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        var term = request.term;
                        if (term in cache) {
                            ajax.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                            response(cache[term]);
                            return;
                        }
                        var paramaters = { term: term, contentType: selector.data("contenttype") };
                        //var paramaters = { term: term, contentType: selector.data("contenttype") }
                        lastXhr = ajax.Get("/admin/contentpage/ContentAutocomplete", paramaters, function (data, status, xhr) {
                            cache[term] = data;
                            if (xhr === lastXhr) {
                                response(data);
                            }
                        });
                    },
                    focus: function (event, ui) {
                        $(selector).val(ui.item.Name);
                        $(selector).attr("data-itemid", ui.item.Id);
                        return false;
                    },
                    select: function (event, ui) {
                        var itemData = JSON.parse(ui.item.Data);
                        var url = itemData.Url;
                        itemSelected = true;
                        $(selector).val(ui.item.Name);
                        $(selector).attr("data-itemid", ui.item.Id);
                        //$(selector).attr("data-itemTypeId", ui.item.ItemTypeId);
                        $(selector).attr("data-url", url);
                        return false;
                    },
                    close: function (event) {
                        Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                    },
                    minLength: 1
                }).data("ui-autocomplete")._renderItem = function (ul, item) {
                    //if the Id is less than 0, then it is a heading
                    if (item.Id > 0) {
                        return $("<li></li>")
                            .data("ui-autocomplete-item", item)
                            .append("<a>" + item.Name + "</a>")
                            .appendTo(ul);
                    }
                    else {
                        return $("<li></li>")
                            .append("<h2>" + item.Name + "</h2>")
                            .appendTo(ul);
                    }
                };
            };
            return TinyMceAutoComplete;
        }(Wdot.BaseSelectedItem));
        Widgets.TinyMceAutoComplete = TinyMceAutoComplete;
    })(Widgets = Wdot.Widgets || (Wdot.Widgets = {}));
})(Wdot || (Wdot = {}));