var Wdot;
(function (Wdot) {
    var BingMaps;
    (function (BingMaps) {
        var Factory;
        (function (Factory) {
            var PinOptionsFactory = (function () {
                function PinOptionsFactory() {
                }
                PinOptionsFactory.prototype.getPinOptions = function (pushPinOptionsEnum) {
                    var defaultPinOptions = this.getDefaultPinOptions();
                    var pinOptions;
                    switch (pushPinOptionsEnum) {
                        case Wdot.Enums.PushPinOptionsEnum.PinImageWithText:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageWithText();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageWithOutText:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageWithOutText();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForRoadTrip:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForRoadTrip();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageWithTextListingPage:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageWithTextListingPage();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForTripCityPlayLocations:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForTripCityPlayLocations();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForTripCityPlayLocationsHighlighted:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForTripCityPlayLocationsHighlighted();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForTripCityStayLocations:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForTripCityStayLocations();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForTripCityStayLocationsHighlighted:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForTripCityStayLocationsHighlighted();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForTripCityEatLocations:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForTripCityEatLocations();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForTripCityEatLocationsHighlighted:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForTripCityEatLocationsHighlighted();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForTripCityEventLocations:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForTripCityEventLocations();
                            break;
                        case Wdot.Enums.PushPinOptionsEnum.PinImageForTripCityEventLocationsHighlighted:
                            pinOptions = new Wdot.BingMaps.PushPinOptions.PushPinOptionsPinImageForTripCityEventLocationsHighlighted();
                            break;
                    }
                    return pinOptions.getPushPinOptions(defaultPinOptions);
                };
                PinOptionsFactory.prototype.getDefaultPinOptions = function () {
                    var baseOptions;
                    var offset = new Microsoft.Maps.Point(0, 5);
                    baseOptions = {
                        visible: true,
                        textOffset: offset,
                        draggable: false
                    };
                    return baseOptions;
                };
                return PinOptionsFactory;
            }());
            Factory.PinOptionsFactory = PinOptionsFactory;
        })(Factory = BingMaps.Factory || (BingMaps.Factory = {}));
    })(BingMaps = Wdot.BingMaps || (Wdot.BingMaps = {}));
})(Wdot || (Wdot = {}));