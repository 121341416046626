var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var MultiPinMap = /** @class */ (function (_super) {
            __extends(MultiPinMap, _super);
            function MultiPinMap(key) {
                var _this = _super.call(this, key) || this;
                _this.map = null;
                _this.pinLocationsArray = null;
                _this.isRoadTrip = false;
                _this.mapElement = null;
                _this.center = null;
                _this.isMiniMap = false;
                return _this;
            }
            MultiPinMap.prototype.loadMap = function (locationsArray, zoomLevel, mapElement, eventLocations, pinWithInfoBoxLocation) {
                this.pinLocationsArray = locationsArray;
                var self = this;
                if (mapElement !== undefined && mapElement != null) {
                    this.mapElement = mapElement;
                }
                else {
                    this.mapElement = document.getElementById('multiPinMap');
                }
                var latitude = 44.832017349893526, longitude = -89.84687250000002, center = new Microsoft.Maps.Location(latitude, longitude);
                this.center = center;
                this.map = new Microsoft.Maps.Map(this.mapElement, {
                    enableClickableLogo: false,
                    showMapTypeSelector: false,
                    showDashboard: true,
                    showBreadcrumb: false,
                    credentials: this.apiKey,
                    zoom: 5,
                    center: center,
                    disableScrollWheelZoom: true,
                    showZoomButtons: this.isMiniMap ? false : this.showZoomControls,
                    showScalebar: !this.isMiniMap,
                    showLocateMeButton: this.isMiniMap ? false : this.showZoomControls
                });
                this.map.getZoomRange = function () {
                    return { max: 15, min: 5 };
                };
                var x = 0;
                ko.utils.arrayForEach(this.pinLocationsArray, function (item) {
                    item.PinText = x + 1;
                    self.addLocation(item);
                    x++;
                });
                if (zoomLevel != 'undefined' && zoomLevel != null && x == 1) {
                    var center_1 = new Microsoft.Maps.Location(locationsArray[0].Latitude, locationsArray[0].Longitude);
                    this.map.setView({
                        center: center_1,
                        zoom: zoomLevel
                    });
                }
                else {
                    this.bestMapView($("#multiPinMap .MicrosoftMap").width(), $("#multiPinMap .MicrosoftMap").height(), 75);
                }
                //this.bindMethodToPreventPinterestPinningMapTiles(self.map);
            };
            MultiPinMap.prototype.addLocation = function (item, pinOptions) {
                var offset = new Microsoft.Maps.Point(0, 5);
                if (pinOptions == null) {
                    pinOptions = {
                        visible: true,
                        textOffset: offset,
                        draggable: false
                    };
                }
                if (this.isRoadTrip) {
                    pinOptions = this.pinOptionsFactory.getPinOptions(Wdot.Enums.PushPinOptionsEnum.PinImageForRoadTrip);
                }
                else {
                    pinOptions = this.pinOptionsFactory.getPinOptions(Wdot.Enums.PushPinOptionsEnum.PinImageWithTextListingPage);
                }
                var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(item.Latitude, item.Longitude), pinOptions);
                var location = new Microsoft.Maps.Location(item.Latitude, item.Longitude);
                this.map.entities.push(pin);
                return pin;
            };
            MultiPinMap.prototype.ResetMapCenter = function () {
                if (this.center) {
                    this.map.setView({ center: this.center });
                }
            };
            return MultiPinMap;
        }(Maps.BingMaps));
        Maps.MultiPinMap = MultiPinMap;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));