var Wdot;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var Coordinates = (function () {
            function Coordinates(pointsList) {
                if (!pointsList) {
                    this.pointsList = ko.observableArray();
                }
                else {
                    this.pointsList = pointsList;
                }
            }
            return Coordinates;
        }());
        Maps.Coordinates = Coordinates;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));