var Wdot;
(function (Wdot) {
    var Enums;
    (function (Enums) {
        var PushPinOptionsEnum;
        (function (PushPinOptionsEnum) {
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageWithText"] = 0] = "PinImageWithText";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageWithOutText"] = 1] = "PinImageWithOutText";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForRoadTrip"] = 2] = "PinImageForRoadTrip";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageWithTextListingPage"] = 3] = "PinImageWithTextListingPage";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForTripCityPlayLocations"] = 4] = "PinImageForTripCityPlayLocations";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForTripCityPlayLocationsHighlighted"] = 5] = "PinImageForTripCityPlayLocationsHighlighted";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForTripCityStayLocations"] = 6] = "PinImageForTripCityStayLocations";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForTripCityStayLocationsHighlighted"] = 7] = "PinImageForTripCityStayLocationsHighlighted";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForTripCityEatLocations"] = 8] = "PinImageForTripCityEatLocations";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForTripCityEatLocationsHighlighted"] = 9] = "PinImageForTripCityEatLocationsHighlighted";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForTripCityEventLocations"] = 10] = "PinImageForTripCityEventLocations";
            PushPinOptionsEnum[PushPinOptionsEnum["PinImageForTripCityEventLocationsHighlighted"] = 11] = "PinImageForTripCityEventLocationsHighlighted";
        })(PushPinOptionsEnum = Enums.PushPinOptionsEnum || (Enums.PushPinOptionsEnum = {}));
    })(Enums = Wdot.Enums || (Wdot.Enums = {}));
})(Wdot || (Wdot = {}));