var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var PolygonCreator = (function (_super) {
            __extends(PolygonCreator, _super);
            function PolygonCreator() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.centerLat = 0;
                _this.centerLong = 0;
                _this.multipolygon = ko.observableArray();
                _this.points = [ko.observableArray()];
                _this.currentpolygon = 0;
                _this.currentMaxPinText = 1;
                _this.toggleShape = function (d) {
                    var num = d.getPolygonNumber();
                    if ($('[data-polygonnum=' + num + ']').hasClass('hidden')) {
                        $('[data-polygonnum=' + num + ']').removeClass('hidden');
                        $('[data-polygonnum-toggle=' + num + ']').removeClass('sprite-expand');
                        $('[data-polygonnum-toggle=' + num + ']').addClass('sprite-collapse');
                    }
                    else {
                        $('[data-polygonnum=' + num + ']').addClass('hidden');
                        $('[data-polygonnum-toggle=' + num + ']').addClass('sprite-expand');
                        $('[data-polygonnum-toggle=' + num + ']').removeClass('sprite-collapse');
                    }
                };
                _this.cleanUpExtraPins = function () {
                    var polygonsToDelete = [];
                    $(this.multipolygon()).each(function (i1) {
                        var pointsList = this;
                        var length = pointsList.pointsList().length;
                        if (length < 4) {
                            polygonsToDelete.push(i1);
                        }
                        else {
                            var firstPin_1 = pointsList.pointsList()[0];
                            var polygonClosureIndex_1 = 0;
                            $(pointsList.pointsList()).each(function (i) {
                                if (this.latitude() == firstPin_1.latitude() && this.longitude() == firstPin_1.longitude() && i != 0 && i != length - 1) {
                                    polygonClosureIndex_1 = i;
                                }
                            });
                            if (polygonClosureIndex_1 > 0) {
                                this.points[i1](this.points[i1].splice(0, polygonClosureIndex_1 + 1));
                                pointsList.pointsList(pointsList.pointsList.splice(0, polygonClosureIndex_1 + 1));
                            }
                        }
                    });
                    $(polygonsToDelete.reverse()).each(function (i) {
                        this.points.splice(polygonsToDelete[i], 1);
                        this.multipolygon().splice(polygonsToDelete[i], 1);
                    });
                    this.render();
                };
                _this.init = function (mapSelector, latitude, longitude) {
                    this.centerLat = latitude;
                    this.centerLong = longitude;
                    $(mapSelector).bind('contextmenu', function (event) {
                        return false;
                    });
                    if ($("#MultiPolygonJson").val() != '') {
                        var jsonString = $("#MultiPolygonJson").val();
                        var json = $.parseJSON(jsonString);
                        var self_1 = this;
                        $(json).each(function (i, object) {
                            $(object.Polygons).each(function (index, value) {
                                self_1.points[index] = ko.observableArray();
                                self_1.multipolygon.push(new Maps.Coordinates());
                                $(value.Coordinates).each(function (index2, value2) {
                                    if (index2 > 2 && value2.latitude == self_1.points[index]()[0].latitude && value2.longitude == self_1.points[index]()[0].longitude) {
                                        self_1.points[index]().push(self_1.points[index]()[0]);
                                    }
                                    else {
                                        var location = new Microsoft.Maps.Location(value2.latitude, value2.longitude);
                                        self_1.points[index].push(location);
                                    }
                                    self_1.multipolygon()[index].pointsList.push(new Maps.Point(index2 + '_' + index, value2.latitude, value2.longitude));
                                });
                                if (self_1.isClosed(index)) {
                                    self_1.currentpolygon++;
                                }
                            });
                        });
                        this.render();
                    }
                    if (this.centerLat != 0 && this.centerLong != 0) {
                        this.map.setView({ zoom: 10, center: new Microsoft.Maps.Location(this.centerLat, this.centerLong) });
                    }
                };
                _this.click = function (event) {
                    var point1 = new Microsoft.Maps.Point(event.getX(), event.getY());
                    if (!event.originalEvent.ctrlKey && event.targetType != 'pushpin') {
                        var location_1 = event.target.tryPixelToLocation(point1);
                        var longitude = location_1.longitude;
                        var latitude = location_1.latitude;
                        this.addPoint(longitude, latitude, point1);
                    }
                    else if (!event.originalEvent.ctrlKey && event.targetType == 'pushpin') {
                        var i = Maps.BingMapsHelpers.getIrefIndexValuesFromPoint(event.target.iref);
                        if (i.polygonNum == this.currentpolygon && i.index == 0 && !this.isClosed(i.polygonNum)) {
                            this.points[i.polygonNum].push(this.points[i.polygonNum]()[0]);
                            var firstPoint = this.multipolygon()[i.polygonNum].pointsList()[0];
                            var iref = this.multipolygon()[i.polygonNum].pointsList().length + "_" + i.polygonNum;
                            this.multipolygon()[i.polygonNum].pointsList.push(new Maps.Point(iref, firstPoint.latitude(), firstPoint.longitude()));
                            this.currentpolygon = this.points.length;
                        }
                        this.render();
                    }
                    else if (event.originalEvent.ctrlKey && event.targetType == 'pushpin') {
                        var i_1 = Maps.BingMapsHelpers.getIrefIndexValuesFromPoint(event.target.iref);
                        if (i_1.polygonNum == this.currentpolygon || this.isClosed(this.currentpolygon)) {
                            this.points[i_1.polygonNum].splice(i_1.index, 1);
                            this.multipolygon()[i_1.polygonNum].pointsList.splice(i_1.index, 1);
                            if (i_1.polygonNum != this.currentpolygon) {
                                this.currentpolygon = i_1.polygonNum;
                            }
                            else {
                                if (this.points[i_1.polygonNum]().length == 0) {
                                    this.points.splice(i_1.polygonNum, 1);
                                    this.multipolygon.splice(i_1.polygonNum, 1);
                                    this.currentpolygon = this.multipolygon().length;
                                }
                            }
                            if (this.multipolygon()[i_1.polygonNum] != undefined) {
                                var p = this.multipolygon()[i_1.polygonNum].pointsList();
                                var l = p.length;
                                if (l <= 3 && l > 1 && p[0].latitude() == p[(l - 1)].latitude() && p[0].longitude() == p[(l - 1)].longitude()) {
                                    p.splice((l - 1), 1);
                                    this.points[i_1.polygonNum].splice((l - 1), 1);
                                }
                            }
                            this.render();
                        }
                        else {
                            alert("You must close the current shape before changing an older shape!");
                        }
                    }
                };
                _this.addPoint = function (longitude, latitude) {
                    if (this.multipolygon()[this.currentpolygon] == null || this.multipolygon()[this.currentpolygon] == undefined) {
                        this.multipolygon.push(new Maps.Coordinates());
                    }
                    if (this.points[this.currentpolygon] == null || this.points[this.currentpolygon] == undefined) {
                        this.points[this.currentpolygon] = ko.observableArray();
                    }
                    if (!this.isClosed(this.currentpolygon)) {
                        var index2 = this.multipolygon()[this.currentpolygon].pointsList().length;
                        this.multipolygon()[this.currentpolygon].pointsList.push(new Maps.Point(index2 + '_' + this.currentpolygon, latitude, longitude));
                        var location = new Microsoft.Maps.Location(latitude, longitude);
                        this.points[this.currentpolygon].push(location);
                        this.render();
                    }
                };
                _this.movePin = function (event) {
                    if (event.originalEvent.button == 2) {
                        //var ua = $..browser;
                        if (navigator.userAgent.indexOf("Firefox") != -1) {
                            this.click(event);
                        }
                        return;
                    }
                    var location = event.target.getLocation(), latitude = location.latitude, longitude = location.longitude, i = Maps.BingMapsHelpers.getIrefIndexValuesFromPoint(event.target.iref);
                    this.points[i.polygonNum]()[i.index].latitude = latitude;
                    this.points[i.polygonNum]()[i.index].longitude = longitude;
                    this.multipolygon()[i.polygonNum].pointsList()[i.index].latitude(latitude);
                    this.multipolygon()[i.polygonNum].pointsList()[i.index].longitude(longitude);
                    var lastPointIndex = (this.points[i.polygonNum]().length - 1);
                    if (i.index == 0) {
                        this.points[i.polygonNum]()[lastPointIndex] = this.points[i.polygonNum]()[0];
                        this.multipolygon()[i.polygonNum].pointsList()[lastPointIndex] = this.multipolygon()[i.polygonNum].pointsList()[0];
                    }
                    this.render();
                };
                _this.isClosed = function (polygonNum) {
                    if (this.points[polygonNum] == null || this.points[polygonNum] == undefined) {
                        return true;
                    }
                    var length = this.points[polygonNum]().length;
                    if (length > 3 && this.points[polygonNum]()[0].latitude == this.points[polygonNum]()[length - 1].latitude && this.points[polygonNum]()[0].longitude == this.points[polygonNum]()[length - 1].longitude) {
                        return true;
                    }
                    else {
                        return false;
                    }
                };
                _this.render = function () {
                    this.map.entities.clear();
                    this.currentMaxPinText = 1;
                    for (var i = 0; i < this.points.length; i++) {
                        this.map.entities.push(new Microsoft.Maps.Polyline(this.points[i]()));
                        for (var j = 0; j < this.points[i]().length; j++) {
                            var location_2 = this.points[i]()[j];
                            if (j > 1 && location_2.latitude == this.points[i]()[0].latitude && location_2.longitude == this.points[i]()[0].longitude) {
                                continue;
                            }
                            var offset = new Microsoft.Maps.Point(0, 5), edit_pin = new Microsoft.Maps.Pushpin(location_2, {
                                draggable: true,
                                text: this.currentMaxPinText.toString(),
                                textOffset: offset,
                                visible: true
                            });
                            //edit_pin.iref = j + "_" + i;
                            this.map.entities.push(edit_pin);
                            Microsoft.Maps.Events.addHandler(edit_pin, 'mouseup', this.movePin);
                            this.multipolygon()[i].pointsList()[j].pinText(this.currentMaxPinText++);
                            //this.multipolygon()[i].pointsList()[j].iref(edit_pin.iref);
                        }
                    }
                    this.createPolygonJson();
                    if (this.validateAllPolygonsClosed() && this.multipolygon().length > 0 && this.multipolygon()[0].pointsList().length > 0) {
                        Wdot.Ajax.prototype.Post("/admin/bingmaps/envelopecenterofsqlgeography", { id: $("#MultiPolygonJson").val() }, function (data) {
                            if (data.longitude != 0 && data.latitude != 0) {
                                this.centerLat = data.latitude;
                                this.centerLong = data.longitude;
                                $("#Latitude").val(this.centerLat);
                                $("#Longitude").val(this.centerLong);
                                this.location = new Microsoft.Maps.Location(this.centerLat, this.centerLong);
                                edit_pin = new Microsoft.Maps.Pushpin(this.location, { draggable: true, text: "center", visible: true });
                                //edit_pin.iref = "center";
                                this.map.entities.push(edit_pin);
                            }
                        }, "json");
                    }
                    else {
                        if (this.multipolygon().length > 1) {
                            this.location = new Microsoft.Maps.Location(this.centerLat, this.centerLong);
                            edit_pin = new Microsoft.Maps.Pushpin(this.location, {
                                draggable: true,
                                text: "center",
                                visible: true
                            });
                            //edit_pin.iref = "center";
                            this.map.entities.push(edit_pin);
                        }
                    }
                };
                _this.createPolygonJson = function () {
                    var json = { Polygons: [] };
                    for (var i = 0; i < this.multipolygon().length; i++) {
                        var coordsLength = this.multipolygon()[i].pointsList().length;
                        if (coordsLength > 0) {
                            json.Polygons.push({ Coordinates: [] });
                            for (var j = 0; j < coordsLength; j++) {
                                json.Polygons[i].Coordinates.push({ latitude: this.multipolygon()[i].pointsList()[j].latitude(), longitude: this.multipolygon()[i].pointsList()[j].longitude() });
                            }
                        }
                    }
                    $("#MultiPolygonJson").val(JSON.stringify(json));
                };
                _this.clearPins = function () {
                    for (var i = this.multipolygon().length - 1; i >= 0; i--) {
                        this.multipolygon()[i].pointsList.removeAll();
                        this.points[i].removeAll();
                        if (i > 0) {
                            this.multipolygon.splice(i, 1);
                            this.points.splice(i, 1);
                        }
                    }
                    this.render();
                    this.currentpolygon = 0;
                };
                _this.validateAllPolygonsClosedEvent = function (event) {
                    this.cleanUpExtraPins();
                    if (!this.validateAllPolygonsClosed()) {
                        alert("There needs to be at least one shape and all shapes must be closed.");
                        event.stopImmediatePropagation();
                        return false;
                    }
                };
                _this.validateAllPolygonsClosed = function () {
                    if (this.multipolygon == null || this.multipolygon().length == 0 || this.multipolygon()[0] == null || this.multipolygon()[0].pointsList().length == 0) {
                        return false;
                    }
                    for (var i = 0; i < this.multipolygon().length; i++) {
                        if (!this.isClosed(i)) {
                            return false;
                        }
                    }
                    return true;
                };
                return _this;
            }
            return PolygonCreator;
        }(Maps.BingMaps));
        Maps.PolygonCreator = PolygonCreator;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));