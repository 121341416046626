function displayLatLongSelectorPin(lat, lon, name) {
    if (lat != undefined && lat != 0 && lon != undefined && lon != 0) {
        wdot.LatLongSelector.latLongViewModel.addItem({ name: (name != "" ? name : "City"), id: 0, data: { LatitudeOverride: lat, Latitude: lat, LongitudeOverride: lon, Longitude: lon, IsOverridden: false } }, 'geoCoded');
    }

    $("[name=Latitude]").change(function () {
        if ($(this).val() != undefined && $(this).val() != 0 && $('[name=Longitude]').val() != undefined && $('[name=Longitude]').val() != 0) {
            wdot.LatLongSelector.latLongViewModel.removeLatLongItemsByKey('geoCoded');
            wdot.LatLongSelector.latLongViewModel.addItem({ name: 'City', id: 0, data: { LatitudeOverride: $(this).val(), Latitude: $(this).val(), LongitudeOverride: $('[name=Longitude]').val(), Longitude: $('[name=Longitude]').val(), IsOverridden: false } }, 'geoCoded');
        }
    });

    $("[name=Longitude]").change(function () {
        if ($(this).val() != undefined && $(this).val() != 0 && $('[name=Latitude]').val() != undefined && $('[name=Latitude]').val() != 0) {
            wdot.LatLongSelector.latLongViewModel.removeLatLongItemsByKey('geoCoded');
            wdot.LatLongSelector.latLongViewModel.addItem({ name: 'City', id: 0, data: { LatitudeOverride: $('[name=Latitude]').val(), Latitude: $('[name=Latitude]').val(), LongitudeOverride: $(this).val(), Longitude: $(this).val(), IsOverridden: false } }, 'geoCoded');
        }
    });
}