var Wdot;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var Point = (function () {
            function Point(i, la, lo) {
                var _this = this;
                this.pinText = ko.observable('');
                this.iref = ko.observable(i);
                this.latitude = ko.observable(la);
                this.longitude = ko.observable(lo);
                this.getPolygonNumber = ko.computed({
                    owner: this,
                    read: function () {
                        //self = this;
                        return Maps.BingMapsHelpers.getIrefIndexValuesFromPoint(_this.iref()).polygonNum;
                    }
                });
            }
            Point.prototype.getIndex = function () {
                var self = this;
                return Maps.BingMapsHelpers.getIrefIndexValuesFromPoint(self.iref()).index;
            };
            return Point;
        }());
        Maps.Point = Point;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));