var wdot = wdot || {};
wdot.User = function () {
    var id = ko.observable(),
            screenName = ko.observable(),
            name = ko.observable(),
            inputNameForId = '',
            index = 0,
            loadUsersAutocomplete = function (textboxSelector, selectorFind) {
                var cache = {},
                lastXhr = '',
                itemSelected = false;
                $(textboxSelector).find(selectorFind).autocomplete({
                    source: function (request, response) {
                        itemSelected = false;
                        response([{ Id: "AJAX", Name: ''}]);
                        Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        var term = request.term;
                        if (term in cache) {
                            Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                            response(cache[term]);
                            return;
                        }
                        lastXhr = Wdot.Ajax.prototype.Get("/admin/users/usersautocomplete", request, function (data, status, xhr) {
                            cache[term] = data;
                            if (xhr === lastXhr) {
                                response(data);
                            }
                        });
                    },
                    focus: function (event, ui) {
                        $(textboxSelector).find(selectorFind).val(ui.item.Name);
                        return false;
                    },
                    select: function (event, ui) {
                        itemSelected = true;
                        $(textboxSelector).find(selectorFind).val(ui.item.Name);
                        $(textboxSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
                        $(textboxSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                        return false;
                    },
                    close: function (event) {
                        Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                    },
                    minLength: 1
                }).data("ui-autocomplete")._renderItem = function (ul, item) {
                    return $("<li></li>")
                    .data("ui-autocomplete-item", item)
                    .append("<a data-itemid='" + item.Id + "'>" + item.Name + "</a>")
                    .appendTo(ul);
                };
            },
            loadAuthorsAutocomplete = function (textboxSelector, selectorFind, blogArticleType) {
                var cache = {},
                lastXhr = '',
                itemSelected = false;
                $(textboxSelector).find(selectorFind).autocomplete({
                    source: function (request, response) {
                        itemSelected = false;
                        response([{ Id: "AJAX", Name: ''}]);
                        Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        var term = request.term;
                        if (term in cache) {
                            Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                            response(cache[term]);
                            return;
                        }
                        lastXhr = Wdot.Ajax.prototype.Get("/admin/users/authorautocomplete?blogarticletype=" + blogArticleType, request, function (data, status, xhr) {
                            cache[term] = data;
                            if (xhr === lastXhr) {
                                response(data);
                            }
                        });
                    },
                    focus: function (event, ui) {
                        $(textboxSelector).find(selectorFind).val(ui.item.Name);
                        return false;
                    },
                    select: function (event, ui) {
                        itemSelected = true;
                        $(textboxSelector).find(selectorFind).val(ui.item.Name);
                        $(textboxSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
                        $(textboxSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                        return false;
                    },
                    close: function (event) {
                        Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                    },
                    minLength: 1
                }).data("ui-autocomplete")._renderItem = function (ul, item) {
                    return $("<li></li>")
                    .data("ui-autocomplete-item", item)
                    .append("<a data-itemid='" + item.Id + "'>" + item.Name + "</a>")
                    .appendTo(ul);
                };
            },
            loadContactsAutocomplete = function (textboxSelector, selectorFind) {
                var cache = {},
                lastXhr = '',
                itemSelected = false;
                $(textboxSelector).find(selectorFind).autocomplete({
                    source: function (request, response) {
                        itemSelected = false;
                        response([{ Id: "AJAX", Name: ''}]);
                        Wdot.Ajax.prototype.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        var term = request.term;
                        if (term in cache) {
                            Wdot.Ajax.prototype.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                            response(cache[term]);
                            return;
                        }
                        lastXhr = Wdot.Ajax.prototype.Get("/admin/users/contactautocomplete", request, function (data, status, xhr) {
                            cache[term] = data;
                            if (xhr === lastXhr) {
                                response(data);
                            }
                        });
                    },
                    focus: function (event, ui) {
                        $(textboxSelector).find(selectorFind).val(ui.item.Name);
                        return false;
                    },
                    select: function (event, ui) {
                        itemSelected = true;
                        $(textboxSelector).find(selectorFind).val(ui.item.Name);
                        $(textboxSelector).find(selectorFind).attr("data-itemid", ui.item.Id);
                        $(textboxSelector).find(selectorFind).attr("data-listItemData", ui.item.Data);
                        return false;
                    },
                    close: function (event) {
                        Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                    },
                    minLength: 1
                }).data("ui-autocomplete")._renderItem = function (ul, item) {
                    return $("<li></li>")
                    .data("ui-autocomplete-item", item)
                    .append("<a data-itemid='" + item.Id + "'>" + item.Name + "</a>")
                    .appendTo(ul);
                };
            },
            createEditUserViewModel = function () {
                var groups = ko.observableArray([]),
                allGroups = new Array(),
                loadAllGroups = function (allGroupsJson) {
                    allGroups = $.parseJSON(allGroupsJson);
                },
                addGroup = function () {
                    var alreadyAdded = false;
                    var groupId = $("#GroupsDropDown").val();
                    for (var i = 0; i < groups().length; i++) {
                        if (groups()[i].Id === parseInt(groupId)) {
                            alreadyAdded = true;
                        }
                    }

                    if (!alreadyAdded) {
                        $.each(allGroups, function () {
                            if (this.Id === parseInt(groupId)) {
                                groups.push(this);
                            }
                        });
                    }
                },
                removeGroup = function (group) {
                    groups.remove(group);
                },
                loadGroups = function (groupsParam) {
                    var json = $.parseJSON(groupsParam);

                    for (var prop in json) {
                        if (!json.hasOwnProperty(prop)) {
                            continue;
                        }

                        $.each(allGroups, function () {
                            if (this.Id === json[prop]["Id"]) {
                                groups.push(this);
                            }
                        });
                    }
                },
                roles = ko.observableArray([]),
                allRoles = new Array(),
                loadAllRoles = function (allRolesJson) {
                    allRoles = $.parseJSON(allRolesJson);
                },
                addRole = function () {
                    var alreadyAdded = false;
                    var roleId = $("#RolesDropDown").val();
                    for (var i = 0; i < roles().length; i++) {
                        if (roles()[i].Id === parseInt(roleId)) {
                            alreadyAdded = true;
                        }
                    }

                    if (!alreadyAdded) {
                        $.each(allRoles, function () {
                            if (this.Id === parseInt(roleId)) {
                                roles.push(this);
                            }
                        });
                    }
                },
                removeRole = function (role) {
                    roles.remove(role);
                },
                loadRoles = function (rolesParam) {
                    var json = $.parseJSON(rolesParam);
                    if (allRoles.length === 0) {
                        loadAllRoles();
                    }

                    for (var prop in json) {
                        if (!json.hasOwnProperty(prop)) {
                            continue;
                        }

                        $.each(allRoles, function () {
                            if (this.Id === json[prop]["Id"]) {
                                roles.push(this);
                            }
                        });
                    }
                };
                return {
                    groups: groups,
                    allGroups: allGroups,
                    addGroup: addGroup,
                    loadAllGroups: loadAllGroups,
                    removeGroup: removeGroup,
                    loadGroups: loadGroups,
                    roles: roles,
                    allRoles: allRoles,
                    addRole: addRole,
                    loadAllRoles: loadAllRoles,
                    removeRole: removeRole,
                    loadRoles: loadRoles
                };
            } (),
            listingPageViewModel = function () {
                var generateListingFilterJSON = function () {
                    var filterKeyword = $("input[name='filterKeyword']").val();
                    var selectedActivity = $("input[name='SelectedVersionStatuses']:checked").val();
                    var id = $("input[name='Id']").val();
                    var name = $("input[name='Name']").val();
                    var screenName = $("input[name='ScreenName']").val();
                    var groups = $("input[name*='Groups.SelectedItems']");
                    var roles = $("input[name*='Roles.SelectedItems']");
                    var queryString = '';

                    if (selectedActivity == 1) {
                        wdot.Paging.data.IncludeInActive = false;
                        wdot.Paging.data.IncludeActive = true;
                        queryString += '&IncludeInActive=false';
                        queryString += '&IncludeActive=true';
                    }
                    else if (selectedActivity == 2) {
                        wdot.Paging.data.IncludeActive = false;
                        wdot.Paging.data.IncludeInActive = true;
                        queryString += '&IncludeInActive=true';
                        queryString += '&IncludeActive=false';
                    } else {
                        wdot.Paging.data.IncludeActive = true;
                        wdot.Paging.data.IncludeInActive = true;
                        queryString += '&IncludeInActive=true';
                        queryString += '&IncludeActive=true';
                    }

                    queryString += '&filterKeyword=' + filterKeyword;
                    queryString += '&Id=' + id;
                    queryString += '&Name=' + name;
                    queryString += '&ScreenName=' + screenName;

                    if (groups.length > 0) {
                        var i = 0;
                        var selectedGroups = {};
                        ko.utils.arrayForEach(groups, function (t) { selectedGroups[i] = $(t).val(); queryString += '&Groups.SelectedItems[' + i + ']=' + $(t).val(); i++; });
                        wdot.Paging.data['Groups.SelectedItems'] = selectedGroups;
                    }
                    else {
                        wdot.Paging.data['Groups.SelectedItems'] = {};
                    }

                    if (roles.length > 0) {
                        var i = 0;
                        var selectedRoles = {};
                        ko.utils.arrayForEach(roles, function (t) { selectedRoles[i] = $(t).val(); queryString += '&Roles.SelectedItems[' + i + ']=' + $(t).val(); i++; });
                        wdot.Paging.data['Roles.SelectedItems'] = selectedRoles;
                    }
                    else {
                        wdot.Paging.data['Roles.SelectedItems'] = {};
                    }



                    wdot.Paging.data.filterKeyword = filterKeyword;
                    wdot.Paging.data.Id = id;
                    wdot.Paging.data.Name = name;
                    wdot.Paging.data.ScreenName = screenName;
                    return queryString;
                },
                resetListingFilter = function () {
                    $("input[name='filterKeyword']").val('');
                    $("input[name='Id']").val('');
                    $("input[name='Name']").val('');
                    $("input[name='ScreenName']").val('');
                    $("input[name='SelectedVersionStatuses']").attr('checked', false);
                    $("input[name='SelectedVersionStatuses'][value='0']").attr('checked', true);
                    var groups = wdot.Listing.listingViewModel.Items.groups();
                    for (var i = groups.length - 1; i >= 0; i--) {
                        wdot.Listing.listingViewModel.Remove(groups[i], 'groups', $("#AllItemsList[data-uniquelistingkey='groups']").find("input[id*='viewAllItem_Name_']"));
                    }
                    var roles = wdot.Listing.listingViewModel.Items.roles();
                    for (var i = roles.length - 1; i >= 0; i--) {
                        wdot.Listing.listingViewModel.Remove(roles[i], 'roles', $("#AllItemsList[data-uniquelistingkey='roles']").find("input[id*='viewAllItem_Name_']"));
                    }
                };
                return {
                    generateListingFilterJSON: generateListingFilterJSON,
                    resetListingFilter: resetListingFilter
                };
            } (); ;
    return {
        createEditUserViewModel: createEditUserViewModel,
        id: id,
        screenName: screenName,
        name: name,
        inputNameForId: inputNameForId,
        index: index,
        loadAuthorsAutocomplete: loadAuthorsAutocomplete,
        loadContactsAutocomplete: loadContactsAutocomplete,
        loadUsersAutocomplete: loadUsersAutocomplete,
        ListingPageViewModel: listingPageViewModel
    };
} ();