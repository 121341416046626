var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var FamilyTripRouteMap = (function (_super) {
            __extends(FamilyTripRouteMap, _super);
            function FamilyTripRouteMap(key) {
                var _this = _super.call(this, key) || this;
                _this.locationsArray = new Array();
                _this.isFirstPin = true;
                _this.generationArray = new Array();
                _this.mapElement = null;
                _this.infoboxLayer = null;
                _this.finalDestination = null;
                _this.allWaypoints = new Array();
                _this.sessionKey = "";
                _this.center = null;
                return _this;
            }
            FamilyTripRouteMap.closeInfobox = function () {
                if (this.pinInfoBox) {
                    this.pinInfoBox.setOptions({ visible: false });
                }
            };
            FamilyTripRouteMap.prototype.loadMap = function (generationRoutes, finalDestination, mapElement) {
                this.infoboxLayer = new Microsoft.Maps.Layer();
                this.generationArray = generationRoutes;
                var latitude = 44.832017349893526, longitude = -89.84687250000002, center = new Microsoft.Maps.Location(latitude, longitude);
                this.center = center;
                if (!mapElement) {
                    mapElement = document.getElementById("familyTripMap");
                }
                this.mapElement = mapElement;
                this.map = new Microsoft.Maps.Map(mapElement, {
                    showMapTypeSelector: false,
                    showDashboard: true,
                    credentials: this.apiKey,
                    zoom: 5,
                    center: center,
                    disablePanning: false,
                    disableScrollWheelZoom: true,
                    showZoomButtons: this.showZoomControls
                });
                this.map.getZoomRange = function () { return { max: 15, min: 5 }; };
                var self = this;
                this.map.getCredentials(function (c) {
                    self.sessionKey = c;
                    //Draw the routes
                    for (var i = 0; i < generationRoutes.length; i++) {
                        try {
                            self.drawRoute(generationRoutes[i], finalDestination[0], generationRoutes[i].RouteInfo);
                            var waypoints = JSON.parse(generationRoutes[i].GenerationWaypoints);
                            for (var j = 0; j < waypoints.length; j++) {
                                self.allWaypoints.push(waypoints[j]);
                            }
                        }
                        catch (e) {
                            console.log(e);
                        }
                    }
                    //Show the pin for the last stop
                    self.isFirstPin = false;
                    var finalPinOptions = {};
                    finalPinOptions.icon = "/Images/public/map_images/final_flag.svg";
                    finalPinOptions.visible = true;
                    finalPinOptions.draggable = false;
                    finalPinOptions.anchor = new Microsoft.Maps.Point(25, 45);
                    if (finalDestination[0]) {
                        self.addFamilyTripPin(finalDestination[0], null, null, finalPinOptions);
                    }
                    self.bestMapViewFamilyTrip($(self.mapElement).children(".MicrosoftMap").width(), $(self.mapElement).children(".MicrosoftMap").height(), 0);
                });
            };
            FamilyTripRouteMap.prototype.drawRoute = function (generationRoute, finalDestination, routeOptions) {
                var options = JSON.parse(routeOptions);
                var self = this;
                if (generationRoute && options) {
                    this.createFamilyRoute(generationRoute, finalDestination, options);
                }
            };
            FamilyTripRouteMap.prototype.addFamilyTripPin = function (location, includeText, generationImageString, pinOptions, infoboxClass) {
                var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(location.Latitude, location.Longitude), pinOptions);
                if (this.isFirstPin) {
                    this.setInfoBox(pin, generationImageString);
                    this.isFirstPin = false;
                    return;
                }
                var self = this;
                Microsoft.Maps.Events.addHandler(pin, 'click', (function () { return self.displayInfobox(pin, location, infoboxClass); }));
                this.map.entities.push(pin);
            };
            //PUSHPIN INFOBOX CALL BACK
            FamilyTripRouteMap.prototype.displayInfobox = function (pin, location, infoboxClass) {
                if (pin) {
                    Wdot.Maps.FamilyTripRouteMap.closeInfobox();
                    var html = "";
                    if (location.FileLocation) {
                        html = "<img src=\"" + (location.FileLocation.replace("~", "")) + "?preset=map-infobox-image\" />";
                    }
                    else {
                        html = '<span class="infobox_title"><iframe width="290" height="290" src="https://www.youtube.com/embed/' + location.YoutubeId + '?autoplay=1" frameborder="0" allowfullscreen></iframe>';
                    }
                    var pushpinFrameHTML = "<div class=\"infobox " + infoboxClass + "\"><a class=\"infobox__close\" onclick=\"Wdot.Maps.FamilyTripRouteMap.closeInfobox()\"><img src=\"../images/public/close-shaded.png\"/></a><div class=\"infobox_content\">{content}<div><a href=\" " + location.LocationUrl + "\">{caption}</a></div></div></div><div class=\"infobox_pointer\"></div>";
                    var infoBox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(0, 0), { visible: false });
                    infoBox.setOptions({
                        visible: true,
                        offset: new Microsoft.Maps.Point(-150, 0),
                        htmlContent: pushpinFrameHTML.replace('{content}', html).replace('{caption}', location.Caption),
                    });
                    //set location of infobox
                    infoBox.setLocation(pin.getLocation());
                    infoBox.setMap(this.map);
                    Wdot.Maps.FamilyTripRouteMap.pinInfoBox = infoBox;
                    var mapViewCenter = new Microsoft.Maps.Location(pin.getLocation().latitude, pin.getLocation().longitude);
                    mapViewCenter.latitude = mapViewCenter.latitude + 1.5;
                    this.map.setView({ center: mapViewCenter });
                    ga('send', 'event', 'ThompsonLP', 'Map Click', location.LocationName);
                }
            };
            FamilyTripRouteMap.prototype.setInfoBox = function (pin, generationImageString) {
                var html = "";
                var generationImage = JSON.parse(generationImageString);
                if (generationImage) {
                    html = "<img src=\"" + (generationImage.FileLocation).replace("~", "") + "?preset=map-generation-image\" width=\"80px\"/>";
                }
                var infoboxOptions = {
                    showPointer: false,
                    offset: new Microsoft.Maps.Point(-100, 0),
                    htmlContent: html
                };
                var infobox = new Microsoft.Maps.Infobox(this.map.getCenter(), infoboxOptions);
                infobox.setLocation(pin.getLocation());
                // Add the info box to the map
                infobox.setMap(this.map);
            };
            FamilyTripRouteMap.prototype.createFamilyRoute = function (generation, finalDestination, routeOptions) {
                this.isFirstPin = true;
                var pinOptions = {};
                pinOptions.icon = routeOptions.PinImageLocation;
                pinOptions.visible = true;
                pinOptions.draggable = false;
                pinOptions.anchor = new Microsoft.Maps.Point(25, 25);
                var waypointStringstring = "";
                var locations = JSON.parse(generation.GenerationWaypoints);
                if (finalDestination) {
                    locations.push(finalDestination);
                }
                for (var i = 0; i < locations.length; i++) {
                    if (i === (locations.length - 1)) {
                        pinOptions.visible = false;
                    }
                    waypointStringstring += "wp." + i + "=" + locations[i].Latitude + "," + locations[i].Longitude + "&";
                    if (i == 0 || locations[i].FileLocation != "" || locations[i].YoutubeId != "") {
                        this.addFamilyTripPin(locations[i], false, generation.GenerationImage, pinOptions, routeOptions.InfoboxClass);
                    }
                }
                var routeRequest = "https://dev.virtualearth.net/REST/v1/Routes/Driving?" + waypointStringstring + "ra=routePath&key=" + this.sessionKey;
                var self = this;
                this.callRestServiceTest(routeRequest, function (response) {
                    if (response &&
                        response.resourceSets &&
                        response.resourceSets.length > 0 &&
                        response.resourceSets[0].resources) {
                        var route = response.resourceSets[0].resources[0];
                        var routePath = route.routePath.line.coordinates;
                        //Generate an array of locations for the route path.
                        var locs = [];
                        for (var i = 0, len = routePath.length; i < len; i++) {
                            locs.push(new Microsoft.Maps.Location(routePath[i][0], routePath[i][1]));
                        }
                        //Draw the route line.
                        var routeColor = new Microsoft.Maps.Color(routeOptions.Color.A, routeOptions.Color.R, routeOptions.Color.G, routeOptions.Color.B);
                        //var routeColor = new Microsoft.Maps.Color(255, routeOptions.Color.R, routeOptions.Color.G, routeOptions.Color.B);
                        var line = new Microsoft.Maps.Polyline(locs, { strokeColor: routeColor, strokeThickness: 8 });
                        self.map.entities.push(line);
                    }
                });
            };
            FamilyTripRouteMap.prototype.bestMapViewFamilyTrip = function (mapWidth, mapHeight, buffer) {
                var center = new Microsoft.Maps.Location(44.832017349893526, -89.84687250000002), zoomLevel = 0, maxLat = -85, minLat = 85, maxLon = -180, minLon = 180, length = this.allWaypoints.length;
                for (var i = 0; i < length; i++) {
                    var location_1 = this.allWaypoints[i];
                    if (location_1.Latitude > maxLat) {
                        maxLat = location_1.Latitude;
                    }
                    if (location_1.Latitude < minLat) {
                        minLat = location_1.Latitude;
                    }
                    if (location_1.Longitude > maxLon) {
                        maxLon = location_1.Longitude;
                    }
                    if (location_1.Longitude < minLon) {
                        minLon = location_1.Longitude;
                    }
                }
                var zoom1 = 15, zoom2 = 15;
                if (length > 0) {
                    center.longitude = (maxLon + minLon) / 2;
                    center.latitude = (maxLat + minLat) / 2;
                    if (maxLon != minLon && maxLat != minLat) {
                        zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
                        zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
                    }
                }
                else {
                    zoom1 = 7;
                    zoom2 = 7;
                }
                zoomLevel = (zoom1 < zoom2) ? zoom1 : zoom2;
                this.map.setView({ center: center, zoom: zoomLevel });
            };
            FamilyTripRouteMap.prototype.callRestServiceTest = function (request, callback) {
                if (callback) {
                    //Create a unique callback function.
                    var uniqueName = this.getUniqueName();
                    request += '&jsonp=' + uniqueName;
                    window[uniqueName] = function (response) {
                        callback(response);
                        delete (window[uniqueName]);
                    };
                    //Make the JSONP request.
                    var script = document.createElement("script");
                    script.setAttribute("type", "text/javascript");
                    script.setAttribute("src", request);
                    document.body.appendChild(script);
                }
            };
            FamilyTripRouteMap.prototype.getUniqueName = function () {
                var name = '__callback' + Math.round(Math.random() * 100000);
                while (window[name]) {
                    name = '__callback' + Math.round(Math.random() * 100000);
                }
                return name;
            };
            return FamilyTripRouteMap;
        }(Maps.BingMaps));
        FamilyTripRouteMap.pinInfoBox = null;
        Maps.FamilyTripRouteMap = FamilyTripRouteMap;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));