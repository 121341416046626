var Wdot;
var _log = false;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var BingMaps = (function () {
            function BingMaps(key) {
                this.hideOverlay = false;
                this.movePinByText = function (text, latitude, longitude) {
                    for (var i = this.map.entities.getLength() - 1; i >= 0; i--) {
                        var pushpin = this.map.entities.get(i);
                        if (pushpin.getText() == text) {
                            pushpin.setLocation(new Microsoft.Maps.Location(latitude, longitude));
                        }
                    }
                };
                this.apiKey = key;
                this.geoCodeBaseUrl = "//dev.virtualearth.net/REST/v1/Locations?output=json&maxResults=1&key=" + key;
                this.pinOptionsFactory = new Wdot.BingMaps.Factory.PinOptionsFactory();
                if ($("[data-isdesktop]").length > 0) {
                    this.showZoomControls = ($("[data-isdesktop]").data("isdesktop").toLowerCase() == "true");
                }
                else {
                    this.showZoomControls = true;
                }
            }
            BingMaps.prototype.bindMethodToPreventPinterestPinningMapTiles = function (m) {
                Microsoft.Maps.Events.addHandler(m, 'tiledownloadcomplete', function () {
                    $(".MicrosoftMap").find("img").attr("nopin", "nopin");
                    if (this.hideOverlay) {
                        $(".OverlaysTL.hidden").removeClass("hidden");
                        $(".OverlaysTL").height(0);
                    }
                });
                setTimeout(function () {
                    $(".MicrosoftMap").find("img").attr("nopin", "nopin");
                }, 750);
            };
            BingMaps.prototype.pinHtml = function (pinText) {
                if (pinText == undefined)
                    pinText = "";
                return "<div class=\"isInTrip\"><div class=\"infoboxText\">" + pinText + "</div><div class=\"point\"></div></div>";
            };
            BingMaps.prototype.loadMap = function (mapId, latitude, longitude, mapElement) {
                var mapDiv;
                if (mapElement) {
                    mapDiv = mapElement;
                }
                else {
                    mapDiv = document.getElementById(mapId);
                }
                if (latitude == undefined || latitude == null || longitude == undefined || longitude == null || longitude == 0 || latitude == 0) {
                    latitude = 44.832017349893526;
                    longitude = -89.84687250000002;
                }
                var center = new Microsoft.Maps.Location(latitude, longitude);
                this.map = new Microsoft.Maps.Map(mapDiv, {
                    showMapTypeSelector: false,
                    credentials: this.apiKey,
                    zoom: 11,
                    center: center,
                    showZoomButtons: this.showZoomControls
                });
                // Remove context menu upon right-click
                $('#' + mapId).bind('contextmenu', function (event) {
                    return false;
                });
            };
            BingMaps.prototype.bestMapView = function (mapWidth, mapHeight, buffer) {
                if(_log) console.group('wdot/bingmaps/bingmaps.js bestMapView');
                if(_log) console.log('mapWidth:', mapWidth);
                if(_log) console.log('mapHeight:', mapHeight);
                if(_log) console.log('buffer:', buffer);
                if (this.map) {
                    var center = new Microsoft.Maps.Location(44.832017349893526, -89.84687250000002), zoomLevel = 0, maxLat = -85, minLat = 85, maxLon = -180, minLon = 180, length_1 = this.map.entities.getLength(), zoom1 = 15, zoom2 = 15;
                    for (var i = 0; i < length_1; i++) {
                        var pin = this.map.entities.get(i);
                        var location_1 = pin.getLocation();
                        if (location_1.latitude > maxLat) {
                            maxLat = location_1.latitude;
                        }
                        if (location_1.latitude < minLat) {
                            minLat = location_1.latitude;
                        }
                        if (location_1.longitude > maxLon) {
                            maxLon = location_1.longitude;
                        }
                        if (location_1.longitude < minLon) {
                            minLon = location_1.longitude;
                        }
                    }
                    if (length_1 > 0) {
                        center.latitude = (maxLat + minLat) / 2;
                        center.longitude = (maxLon + minLon) / 2;
                        if (maxLon != minLon && maxLat != minLat) {
                            zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
                            zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
                        }
                    }
                    else {
                        zoom1 = 7;
                        zoom2 = 7;
                    }
                    zoomLevel = (zoom1 < zoom2) ? zoom1 : zoom2;
                    this.map.setView({ center: center, zoom: zoomLevel });
                    if(_log) alert('wdot/bingmaps/bingmaps.js bestMapView zoomLevel:' + zoomLevel);

                    if(_log) console.log('zoomLevel:', zoomLevel);
                    if(_log) console.groupEnd();
                }
            };
            BingMaps.prototype.addPin = function (latitude, longitude, text, dropPinFunction) {
                if (this.map) {
                    if (text == undefined || text == null) {
                        text = (this.map.entities.getLength() + 1).toString();
                    }
                    var offset = new Microsoft.Maps.Point(0, 5), pinOptions = {
                        text: text.toString(),
                        visible: true,
                        textOffset: offset,
                        draggable: true
                    };
                    if (this.iconOverrideUrl) {
                        pinOptions.icon = this.iconOverrideUrl;
                    }
                    var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(latitude, longitude), pinOptions);
                    Microsoft.Maps.Events.addHandler(pin, 'dragend', dropPinFunction);
                    this.map.entities.push(pin);
                    this.map.setView({
                        center: new Microsoft.Maps.Location(parseFloat(latitude), parseFloat(longitude)),
                        zoom: 10
                    });
                }
            };
            BingMaps.prototype.removePinByText = function (text) {
                var indexRemoved = 0;
                if (this.map) {
                    for (var i = this.map.entities.getLength() - 1; i >= 0; i--) {
                        var pushpin = this.map.entities.get(i);
                        if (pushpin.getText() == text) {
                            this.map.entities.removeAt(i);
                            indexRemoved = i;
                        }
                    }
                    for (var i = indexRemoved; this.map.entities.getLength() > i; i++) {
                        var pushpin = this.map.entities.get(i);
                        var pushpinText = (this.map.entities.indexOf(pushpin) + 1).toString();
                        pushpin.setOptions({ text: pushpinText });
                    }
                }
                return indexRemoved;
            };
            BingMaps.prototype.geoCodeAddress = function (address, callback) {
                if (address.state != undefined && address.city != undefined && address.zip != undefined && address.street != undefined) {
                    var url = this.geoCodeBaseUrl + "&jsonp=" + callback + "&adminDistrict=" + address.state + "&locality=" + address.city + "&postalCode=" + address.zip + "&addressLine=" + address.street;
                    this.callRestService(url);
                }
            };
            BingMaps.prototype.callRestService = function (request) {
                var script = document.createElement("script");
                script.setAttribute("type", "text/javascript");
                script.setAttribute("src", request);
                script.setAttribute("id", "geoCodeRestScript");
                document.body.appendChild(script);
                //$("#geoCodeRestScript").remove();
            };
            BingMaps.prototype.removeAllPins = function () {
                this.map.entities.clear();
            };
            BingMaps.prototype.addEvent = function (eventname, eventfunction) {
                Microsoft.Maps.Events.addHandler(this.map, eventname, eventfunction);
            };
            return BingMaps;
        }());
        Maps.BingMaps = BingMaps;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));