var Wdot;
(function (Wdot) {
    var BingMaps;
    (function (BingMaps) {
        var PushPinOptions;
        (function (PushPinOptions) {
            var PushPinOptionsPinImageWithText = (function () {
                function PushPinOptionsPinImageWithText() {
                }
                PushPinOptionsPinImageWithText.prototype.getPushPinOptions = function (pinOptions, pinText) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/pushpin_3.png?watermark=pintext&text=' + pinText;
                    return pinOptions;
                };
                return PushPinOptionsPinImageWithText;
            }());
            PushPinOptions.PushPinOptionsPinImageWithText = PushPinOptionsPinImageWithText;
            var PushPinOptionsPinImageWithOutText = (function () {
                function PushPinOptionsPinImageWithOutText() {
                }
                PushPinOptionsPinImageWithOutText.prototype.getPushPinOptions = function (pinOptions, pinText) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/pushpin_2.png';
                    return pinOptions;
                };
                return PushPinOptionsPinImageWithOutText;
            }());
            PushPinOptions.PushPinOptionsPinImageWithOutText = PushPinOptionsPinImageWithOutText;
            var PushPinOptionsPinImageForRoadTrip = (function () {
                function PushPinOptionsPinImageForRoadTrip() {
                }
                PushPinOptionsPinImageForRoadTrip.prototype.getPushPinOptions = function (pinOptions, pinText) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/pushpin_roadtrip.png';
                    return pinOptions;
                };
                return PushPinOptionsPinImageForRoadTrip;
            }());
            PushPinOptions.PushPinOptionsPinImageForRoadTrip = PushPinOptionsPinImageForRoadTrip;
            var PushPinOptionsPinImageWithTextListingPage = (function () {
                function PushPinOptionsPinImageWithTextListingPage() {
                }
                PushPinOptionsPinImageWithTextListingPage.prototype.getPushPinOptions = function (pinOptions, pinText) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/location-pin.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(12, 16);
                    return pinOptions;
                };
                return PushPinOptionsPinImageWithTextListingPage;
            }());
            PushPinOptions.PushPinOptionsPinImageWithTextListingPage = PushPinOptionsPinImageWithTextListingPage;
            //Trip Randomizer Pin Options - Possible do something a bit different?
            var PushPinOptionsPinImageForTripCityPlayLocations = (function () {
                function PushPinOptionsPinImageForTripCityPlayLocations() {
                }
                PushPinOptionsPinImageForTripCityPlayLocations.prototype.getPushPinOptions = function (pinOptions) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/trip_randomizer/play.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(27.25, 85.6);
                    return pinOptions;
                };
                return PushPinOptionsPinImageForTripCityPlayLocations;
            }());
            PushPinOptions.PushPinOptionsPinImageForTripCityPlayLocations = PushPinOptionsPinImageForTripCityPlayLocations;
            var PushPinOptionsPinImageForTripCityPlayLocationsHighlighted = (function () {
                function PushPinOptionsPinImageForTripCityPlayLocationsHighlighted() {
                }
                PushPinOptionsPinImageForTripCityPlayLocationsHighlighted.prototype.getPushPinOptions = function (pinOptions) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/trip_randomizer/play-active.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(27.25, 85.6);
                    return pinOptions;
                };
                return PushPinOptionsPinImageForTripCityPlayLocationsHighlighted;
            }());
            PushPinOptions.PushPinOptionsPinImageForTripCityPlayLocationsHighlighted = PushPinOptionsPinImageForTripCityPlayLocationsHighlighted;
            var PushPinOptionsPinImageForTripCityStayLocations = (function () {
                function PushPinOptionsPinImageForTripCityStayLocations() {
                }
                PushPinOptionsPinImageForTripCityStayLocations.prototype.getPushPinOptions = function (pinOptions) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/trip_randomizer/stay.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(27.25, 85.6);
                    return pinOptions;
                };
                return PushPinOptionsPinImageForTripCityStayLocations;
            }());
            PushPinOptions.PushPinOptionsPinImageForTripCityStayLocations = PushPinOptionsPinImageForTripCityStayLocations;
            var PushPinOptionsPinImageForTripCityStayLocationsHighlighted = (function () {
                function PushPinOptionsPinImageForTripCityStayLocationsHighlighted() {
                }
                PushPinOptionsPinImageForTripCityStayLocationsHighlighted.prototype.getPushPinOptions = function (pinOptions) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/trip_randomizer/stay-active.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(27.25, 85.6);
                    return pinOptions;
                };
                return PushPinOptionsPinImageForTripCityStayLocationsHighlighted;
            }());
            PushPinOptions.PushPinOptionsPinImageForTripCityStayLocationsHighlighted = PushPinOptionsPinImageForTripCityStayLocationsHighlighted;
            var PushPinOptionsPinImageForTripCityEatLocations = (function () {
                function PushPinOptionsPinImageForTripCityEatLocations() {
                }
                PushPinOptionsPinImageForTripCityEatLocations.prototype.getPushPinOptions = function (pinOptions) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/trip_randomizer/eat.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(27.25, 85.6);
                    return pinOptions;
                };
                return PushPinOptionsPinImageForTripCityEatLocations;
            }());
            PushPinOptions.PushPinOptionsPinImageForTripCityEatLocations = PushPinOptionsPinImageForTripCityEatLocations;
            var PushPinOptionsPinImageForTripCityEatLocationsHighlighted = (function () {
                function PushPinOptionsPinImageForTripCityEatLocationsHighlighted() {
                }
                PushPinOptionsPinImageForTripCityEatLocationsHighlighted.prototype.getPushPinOptions = function (pinOptions) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/trip_randomizer/eat-active.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(27.25, 85.6);
                    return pinOptions;
                };
                return PushPinOptionsPinImageForTripCityEatLocationsHighlighted;
            }());
            PushPinOptions.PushPinOptionsPinImageForTripCityEatLocationsHighlighted = PushPinOptionsPinImageForTripCityEatLocationsHighlighted;
            var PushPinOptionsPinImageForTripCityEventLocations = (function () {
                function PushPinOptionsPinImageForTripCityEventLocations() {
                }
                PushPinOptionsPinImageForTripCityEventLocations.prototype.getPushPinOptions = function (pinOptions) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/trip_randomizer/event.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(27.25, 85.6);
                    return pinOptions;
                };
                return PushPinOptionsPinImageForTripCityEventLocations;
            }());
            PushPinOptions.PushPinOptionsPinImageForTripCityEventLocations = PushPinOptionsPinImageForTripCityEventLocations;
            var PushPinOptionsPinImageForTripCityEventLocationsHighlighted = (function () {
                function PushPinOptionsPinImageForTripCityEventLocationsHighlighted() {
                }
                PushPinOptionsPinImageForTripCityEventLocationsHighlighted.prototype.getPushPinOptions = function (pinOptions) {
                    if (pinOptions == null || pinOptions == undefined) {
                        pinOptions = {};
                    }
                    pinOptions.icon = '/images/public/map_images/trip_randomizer/event-active.svg';
                    pinOptions.anchor = new Microsoft.Maps.Point(27.25, 85.6);
                    return pinOptions;
                };
                return PushPinOptionsPinImageForTripCityEventLocationsHighlighted;
            }());
            PushPinOptions.PushPinOptionsPinImageForTripCityEventLocationsHighlighted = PushPinOptionsPinImageForTripCityEventLocationsHighlighted;
        })(PushPinOptions = BingMaps.PushPinOptions || (BingMaps.PushPinOptions = {}));
    })(BingMaps = Wdot.BingMaps || (Wdot.BingMaps = {}));
})(Wdot || (Wdot = {}));