// Jim's help toggle

$(function () {
    $('.sprite-help-ltgrey').addClass('toolTip');
    //Toggle Help Pop-up
    $('.toolTip').hover(
        function () {

            var tip = $(this).next();
            tip.fadeIn(200);
            var position = $(this).position();
            var left = (position.left) - 130;
            var horzWindow = $(window).width();
            var helpPosition = $(this).offset();
            tip.css("left", left);
            tip.css("top", -($(this).next().outerHeight() + 3));

            if ((helpPosition.left) + 180 > horzWindow) {
                tip.css("left", position.left - 292);
                tip.css("top", -15);
                tip.addClass('arrowRight');
            }
            else if ($(this).next().offset().top - $(window).scrollTop() < 0) {
                tip.css("top", 44);
                tip.addClass('arrowUp');
            }
            else {
                tip.addClass('arrowDown');
            };
        },
        function () {
            var tip = $(this).next();

            tip.fadeOut(200);
            tip.removeClass('arrowUp arrowDown arrowRight');

        });

});


