var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var Widgets;
    (function (Widgets) {
        var MapAutoComplete = (function (_super) {
            __extends(MapAutoComplete, _super);
            function MapAutoComplete(map, bingMaps) {
                var _this = _super.call(this) || this;
                _this.mapElement = map;
                _this.bingMapsObject = bingMaps;
                return _this;
            }
            MapAutoComplete.prototype.loadAutoComplete = function (selector, url) {
                var cache = {};
                var lastXhr;
                var itemSelected = false;
                var ajax = new Wdot.Ajax();
                var self = this;
                $(selector).autocomplete({
                    source: function (request, response) {
                        itemSelected = false;
                        response([{ Id: "AJAX", Name: '' }]);
                        ajax.AddAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                        var term = request.term;
                        if (term in cache) {
                            ajax.RemoveAjaxLoader($('.ui-autocomplete .ui-menu-item [data-itemid="AJAX"]'));
                            response(cache[term]);
                            return;
                        }
                        var paramaters = { term: term, contentType: selector.data("contenttype") };
                        //var paramaters = { term: term, contentType: selector.data("contenttype") }
                        lastXhr = ajax.Get("/admin/contentpage/ContentAutocomplete", paramaters, function (data, status, xhr) {
                            cache[term] = data;
                            if (xhr === lastXhr) {
                                response(data);
                            }
                        });
                    },
                    focus: function (event, ui) {
                        $(selector).val(ui.item.Name);
                        $(selector).attr("data-itemid", ui.item.Id);
                        return false;
                    },
                    select: function (event, ui) {
                        var itemData = JSON.parse(ui.item.Data);
                        var latitude = itemData.IsOverridden ? itemData.LatitudeOverride : itemData.Latitude;
                        var longitude = itemData.IsOverridden ? itemData.LongitudeOverride : itemData.Longitude;
                        itemSelected = true;
                        $(selector).val(ui.item.Name);
                        $(selector).data("itemid", ui.item.Id);
                        $(selector).data("latitude", latitude);
                        $(selector).data("longitude", longitude);
                        if (self.bingMapsObject.map == null) {
                            self.bingMapsObject.loadMap(null, latitude, longitude, self.mapElement[0]);
                            self.bingMapsObject.iconOverrideUrl = '//ecn.dev.virtualearth.net/mapcontrol/v7.0/7.0.20160525132934.58/i/poi_search.png';
                        }
                        self.bingMapsObject.removeAllPins();
                        self.bingMapsObject.addPin(latitude, longitude, null, function () { });
                        return false;
                    },
                    close: function (event) {
                        Wdot.AutocompleteHelper.closeAutocomplete(itemSelected, this, event);
                    },
                    minLength: 1
                }).data("ui-autocomplete")._renderItem = function (ul, item) {
                    //if the Id is less than 0, then it is a heading
                    if (item.Id > 0) {
                        return $("<li></li>")
                            .data("ui-autocomplete-item", item)
                            .append("<a>" + item.Name + "</a>")
                            .appendTo(ul);
                    }
                    else {
                        return $("<li></li>")
                            .append("<h2>" + item.Name + "</h2>")
                            .appendTo(ul);
                    }
                };
            };
            return MapAutoComplete;
        }(Wdot.BaseSelectedItem));
        Widgets.MapAutoComplete = MapAutoComplete;
    })(Widgets = Wdot.Widgets || (Wdot.Widgets = {}));
})(Wdot || (Wdot = {}));