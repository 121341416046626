var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var MapWithShape = (function (_super) {
            __extends(MapWithShape, _super);
            function MapWithShape(apiKey) {
                var _this = _super.call(this, apiKey) || this;
                _this.center = null;
                return _this;
            }
            MapWithShape.prototype.loadMapWithShape = function (mapId, polygon, latitude, longitude, zoomLevelString, allRegions) {
                var self = this;
                this.center = new Microsoft.Maps.Location(latitude, longitude);
                var polygonPoints = [ko.observableArray()];
                var zoomLevel = parseInt(zoomLevelString);
                if (latitude == undefined || latitude == null || longitude == undefined || longitude == null || longitude == 0 || latitude == 0) {
                    latitude = 44.832017349893526;
                    longitude = -89.84687250000002;
                }
                this.map = new Microsoft.Maps.Map(document.getElementById(mapId), {
                    enableClickableLogo: false,
                    showMapTypeSelector: false,
                    showDashboard: true,
                    showBreadcrumb: false,
                    credentials: this.apiKey,
                    zoom: 11,
                    center: this.center,
                    showLocateMeButton: this.showZoomControls,
                    showZoomButtons: this.showZoomControls
                });
                this.map.getZoomRange = function () {
                    return { max: 15, min: 5 };
                };
                $('#' + mapId).bind('contextmenu', function (event) {
                    return false;
                });
                this.map.entities.clear();
                $(polygon.Polygons[0]).each(function (index, value) {
                    for (var i = 0; i < value.Coordinates.length; i++) {
                        if (i > 2 && value.Coordinates[i].latitude == polygonPoints[index]()[0].latitude
                            && value.Coordinates[i].longitude == polygonPoints[index]()[0].longitude) {
                            polygonPoints[index]().push(polygonPoints[index]()[0]);
                        }
                        else {
                            var location_1 = new Microsoft.Maps.Location(value.Coordinates[i].latitude, value.Coordinates[i].longitude);
                            if (polygonPoints[index] == undefined) {
                                polygonPoints[index] = ko.observableArray();
                            }
                            polygonPoints[index]().push(location_1);
                        }
                    }
                });
                var maxIndexOfFillShapes = polygonPoints.length - 1;
                if (allRegions != undefined) {
                    var allRegionsJson = $.parseJSON(allRegions);
                    var index_1 = maxIndexOfFillShapes + 1;
                    $(allRegionsJson).each(function (regionIndex, regionValue) {
                        //Adjustments because polygonPoints already has an item in it for the selected region
                        $(regionValue.Polygons).each(function (i, value) {
                            if (polygonPoints[index_1] == undefined) {
                                polygonPoints[index_1] = ko.observableArray();
                            }
                            $(value.Coordinates).each(function (index2, value2) {
                                if (index2 > 2 && value2.latitude == polygonPoints[index_1]()[0].latitude && value2.longitude == polygonPoints[index_1]()[0].longitude) {
                                    polygonPoints[index_1]().push(polygonPoints[index_1]()[0]);
                                }
                                else {
                                    var location = new Microsoft.Maps.Location(value2.latitude, value2.longitude);
                                    polygonPoints[index_1].push(location);
                                }
                            });
                            index_1++;
                        });
                    });
                }
                for (var i = 0; i < polygonPoints.length; i++) {
                    if (i <= maxIndexOfFillShapes && allRegions != undefined) {
                        this.map.entities.push(new Microsoft.Maps.Polygon(polygonPoints[i](), {
                            fillColor: new Microsoft.Maps.Color(100, 161, 28, 36),
                            strokeColor: new Microsoft.Maps.Color(255, 161, 28, 36),
                            strokeThickness: 1
                        }));
                    }
                    else {
                        if (allRegions == undefined) {
                            this.map.entities.push(new Microsoft.Maps.Polygon(polygonPoints[i](), {
                                fillColor: new Microsoft.Maps.Color(100, 161, 28, 36),
                                strokeColor: new Microsoft.Maps.Color(255, 161, 28, 36),
                                strokeThickness: 1
                            }));
                        }
                        else {
                            this.map.entities.push(new Microsoft.Maps.Polyline(polygonPoints[i](), {
                                strokeColor: new Microsoft.Maps.Color(255, 161, 28, 36),
                                strokeThickness: 1
                            }));
                        }
                    }
                }
                if (zoomLevel == null || zoomLevel == undefined || zoomLevel == 0) {
                    zoomLevel = 0;
                    var maxLat = -85, minLat = 85, maxLon = -180, minLon = 180, length_1 = this.map.entities.getLength();
                    for (var i = 0; i < length_1; i++) {
                        var entity = this.map.entities[i], locations = entity._locations;
                        for (var j = 0; j < locations.length; j++) {
                            var location_2 = locations[j];
                            if (location_2.latitude > maxLat) {
                                maxLat = location_2.latitude;
                            }
                            if (location_2.latitude < minLat) {
                                minLat = location_2.latitude;
                            }
                            if (location_2.longitude > maxLon) {
                                maxLon = location_2.longitude;
                            }
                            if (location_2.longitude < minLon) {
                                minLon = location_2.longitude;
                            }
                        }
                    }
                    var zoom1 = 15, zoom2 = 15;
                    if (length_1 > 0) {
                        if (maxLon != minLon && maxLat != minLat) {
                            zoom1 = Math.ceil(Math.log(360.0 / 256.0 * ($(mapId).width() - 2 * 70) / (maxLon - minLon)) / Math.log(2));
                            zoom2 = Math.ceil(Math.log(180.0 / 256.0 * ($(mapId).height() - 2 * 70) / (maxLat - minLat)) / Math.log(2));
                        }
                    }
                    else {
                        zoom1 = 7;
                        zoom2 = 7;
                    }
                    zoomLevel = (zoom1 > zoom2) ? zoom1 : zoom2;
                }
                this.map.setView({ center: this.center, zoom: zoomLevel });
            };
            MapWithShape.prototype.ResetMapCenter = function () {
                if (this.center) {
                    this.map.setView({ center: this.center });
                }
            };
            return MapWithShape;
        }(Maps.BingMaps));
        Maps.MapWithShape = MapWithShape;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));