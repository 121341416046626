var wdot = wdot || {};
wdot.Listing = function () {
    var listItem = function () {
        var id = ko.observable(),
            name = ko.observable(),
            type = ko.observable(),
            index = 0,
            inputName = '',
            inputNameForName = '',
            isInherited = false,
            data = {},
            key = '';
        return {
            id: id,
            name: name,
            type: type,
            index: index,
            inputName: inputName,
            inputNameForName: inputNameForName,
            isInherited: isInherited,
            data: data,
            key: key
        };
    },
    viewAllItem = function () {
        var self = this;
        self.index = ko.observable();
        self.id = ko.observable();
        self.name = ko.observable();
        self.isInherited = ko.observable();
        self.data = ko.observable();
        self.dataString = ko.computed(function () {
            if (typeof self.data() == "string")
                return self.data();

            return ko.toJSON(self.data());
        });
        self.checkboxId = ko.computed(function () {
            return 'viewAllItem_Name_' + self.index();
        });
        self.hiddenId = ko.computed(function () {
            return 'viewAllItem_Id_' + self.index();
        });
        self.key = ko.observable();
        self.isChecked = function () {
            return ko.utils.arrayFilter(wdot.Listing.listingViewModel.Items[self.key()](), function (item) {
                if (item.id == self.id()) {
                    return true;
                }
            }).length > 0;
        };
        return {
            Id: self.id,
            Name: self.name,
            IsInherited: self.isInherited,
            Data: self.data,
            CheckboxId: self.checkboxId,
            HiddenId: self.hiddenId,
            IsChecked: self.isChecked,
            index: self.index,
            key: self.key,
            DataString: self.dataString
        };
    },
    viewAllModel = function () {
        var viewAllItems = ko.observableArray(),
        currentPage = ko.observable(1),
        pageCount = ko.observable(1),
        numPagesToShow = 5,
        hasPages = ko.computed(function () {
            return pageCount() > 1;
        }),
        includeLeftArrow = ko.computed(function () {
            return currentPage() > 1;
        }),
        includeRightArrow = ko.computed(function () {
            return currentPage() < pageCount();
        }),
        startingPageNum = function () {
            var middleNum = Math.ceil(numPagesToShow / 2);
            var startPage = 1;
            if (pageCount() > numPagesToShow) {
                if (currentPage() > middleNum) {
                    startPage += currentPage() - middleNum;
                }

                if ((currentPage() + middleNum) > pageCount()) {
                    startPage = (pageCount() - numPagesToShow) + 1;
                }
            }
            return startPage;
        },
        lastPageNumToDisplay = function () {
            return numPagesToShow < pageCount() ? (numPagesToShow - 1) + startingPageNum() : pageCount();
        },
        fillPageArray = function (url) {
            wdot.Listing.ViewAllModel.PageArray([]);
            for (var i = startingPageNum(); i <= lastPageNumToDisplay(); i++) {
                wdot.Listing.ViewAllModel.PageArray.push({ Num: i, Url: url });
            }
        },
        pageArray = ko.observableArray(),
        changePage = function (d, e) {
            var key = $("#AllItemsList button").parent().attr("data-uniquelistingkey");
            if (key == "PlaceEventLocation") {
                wdot.Event.eventLocationListingViewModel.addItemsFromViewAll(e);
            } else {
                wdot.Listing.listingViewModel.AddItemsFromViewAllGeneric($("#AllItemsList button"), true);
            }
            wdot.Listing.ViewAllModel.ViewAllItems([]);
            Wdot.Ajax.prototype.AddAjaxLoader($("#AllItemsList"));
            ajaxData = {};
            ajaxData = { pageNumber: $(e.currentTarget).attr('data-pagenumber'), filterKeyword: $('#viewAllFilter').val(), key: $('#AllItemsList').attr('data-uniquelistingkey'), ShowCityGroupAsCity: $("[Name='ShowCityGroupAsCity']").is(":checked") };
            addHiddenInputs();
            Wdot.Ajax.prototype.Get($("[data-url]").attr('data-url'), ajaxData, function (data) {
                loadPage(data.ItemsJson, data.CurrentPage, data.PageCount, data.Key, data.Url);
            });
        },
        loadPage = function (itemsString, cp, pc, key, url) {
            currentPage(cp);
            pageCount(pc);
            fillPageArray(url);
            var items = itemsString,
                index = 0;
            wdot.Listing.ViewAllModel.ViewAllItems([]);
            ko.utils.arrayForEach(items, function (i) {
                var vai = new viewAllItem();
                vai.Id(i.Id);
                vai.Name($("<div/>").html(i.Name).text());
                vai.Data(i.Data);
                vai.IsInherited(i.IsInherited);
                vai.index(index);
                vai.key(key);
                index++;
                wdot.Listing.ViewAllModel.ViewAllItems.push(vai);
            });
            if (wdot.Listing.ViewAllModel.ViewAllItems().length == 0) {
                $('#AllItemsList').find("span.no-items").removeClass("hidden");
            }
            else {
                $('#AllItemsList').find("span.no-items").addClass("hidden");
            }
            Wdot.Ajax.prototype.RemoveAjaxLoader($("#AllItemsList"));
            $('.pagingbtn.pagenum[data-pagenumber=' + currentPage() + ']').addClass('activeItem');
        },
        ajaxData = {},
        addHiddenInputs = function () {
            ko.utils.arrayForEach($('.hiddenInputsForAjaxCalls'), function (i) {
                ajaxData[$(i).attr('name')] = $(i).attr('value');
            });
        },
        filter = function (e) {
            var key = $('#AllItemsList').attr('data-uniquelistingkey');
            if (key == "PlaceEventLocation") {
                wdot.Event.eventLocationListingViewModel.addItemsFromViewAll(e);
            }
            else {
                wdot.Listing.listingViewModel.AddItemsFromViewAllGeneric($("#AllItemsList button"), true);
            }
            wdot.Listing.ViewAllModel.ViewAllItems([]);
            Wdot.Ajax.prototype.AddAjaxLoader($("#AllItemsList"));
            ajaxData = {};
            ajaxData = { pageNumber: $(e.currentTarget).attr('data-pagenumber'), filterKeyword: $('#viewAllFilter').val(), key: $('#AllItemsList').attr('data-uniquelistingkey'), ShowCityGroupAsCity: $("[Name='ShowCityGroupAsCity']").is(":checked") };
            addHiddenInputs();
            Wdot.Ajax.prototype.Get($(e.currentTarget).attr('data-url'), ajaxData, function (data) {
                loadPage(data.ItemsJson, 1, data.PageCount, data.Key, data.Url);
            });
        };
        return {
            ViewAllItems: viewAllItems,
            CurrentPage: currentPage,
            PageCount: pageCount,
            HasPages: hasPages,
            IncludeLeftArrow: includeLeftArrow,
            IncludeRightArrow: includeRightArrow,
            PageArray: pageArray,
            FillPageArray: fillPageArray,
            LoadPage: loadPage,
            ChangePage: changePage,
            Filter: filter
        };
    } (),
    listingViewModel = function () {
        var currentIndex = {},
            maxItems = {},
            unlimitedItems = {},
            items = {},
            mvcListBindingName = {},
            inheritedItems = {},
            initListing = function (key) {
                if (wdot.Listing.listingViewModel.CurrentIndex == undefined)
                    wdot.Listing.listingViewModel.CurrentIndex = {};
                wdot.Listing.listingViewModel.CurrentIndex[key] = 0;

                if (wdot.Listing.listingViewModel.MaxItems == undefined)
                    wdot.Listing.listingViewModel.MaxItems = {};
                wdot.Listing.listingViewModel.MaxItems[key] = 1;

                if (wdot.Listing.listingViewModel.UnlimitedItems == undefined)
                    wdot.Listing.listingViewModel.UnlimitedItems = {};
                wdot.Listing.listingViewModel.UnlimitedItems[key] = false;

                if (wdot.Listing.listingViewModel.Items == undefined)
                    wdot.Listing.listingViewModel.Items = {};
                wdot.Listing.listingViewModel.Items[key] = ko.observableArray();

                if (wdot.Listing.listingViewModel.MVCListBindingName == undefined)
                    wdot.Listing.listingViewModel.MVCListBindingName = {};
                wdot.Listing.listingViewModel.MVCListBindingName[key] = '';

                if (wdot.Listing.listingViewModel.InheritedItems == undefined)
                    wdot.Listing.listingViewModel.InheritedItems = {};
                wdot.Listing.listingViewModel.InheritedItems[key] = {};
            },
            subscribeToListingItems = function (key, subscriptionFunction) {
                
                wdot.Listing.listingViewModel.Items[key].subscribe(function (newValue) {
                    subscriptionFunction(newValue, key);
                });
            },
            addMediaGalleryItem = function () {
                window.open('/admin/mediagallery/popup', 'selectMediaGalleryMediaFile', 'location=1,status=1,scrollbars=1, width=800,height=600');
            },
            addItem = function (data, event) {
                add($(event.currentTarget).parent().siblings("[name='UniqueListingKey']").val(), $(event.currentTarget).parent().find("#listItem").val(), $(event.currentTarget).parent().find("#listItem").attr("data-itemid"), $(event.currentTarget).parent().find("#listItem").attr("data-type"), $(event.currentTarget).parent().siblings("#list-view-all").find("input[id*='viewAllItem_Id_']"), $(event.currentTarget).parent().find("#listItem").attr("data-listItemData"));
                $(event.currentTarget).parent().find("#listItem").val('');
            },
            add = function (key, value, itemid, type, viewAllItemsSelector, data) {
                if (value != '' && itemid != 0 && (wdot.Listing.listingViewModel.UnlimitedItems[key] || $("input[name*='" + wdot.Listing.listingViewModel.MVCListBindingName[key] + ".SelectedItems[']").length < wdot.Listing.listingViewModel.MaxItems[key])) {
                    if (wdot.Listing.listingViewModel.MaxItems[key] == 1) {
                        items[key].remove(items[key]()[0]);
                        if (wdot.Listing.listingViewModel.CurrentIndex[key] > 0)
                            wdot.Listing.listingViewModel.CurrentIndex[key]--;
                    }
                    var id = itemid;
                    if (!isInherited(id, key) && !isItemAlreadyAdded(id, key)) {
                        var item = new wdot.Listing.ListItem();
                        item.name = value;
                        item.id = id;
                        item.type = type;
                        item.index = wdot.Listing.listingViewModel.CurrentIndex[key]++;
                        item.inputName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + item.index + ']';
                        item.inputNameForName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedName[' + item.index + ']';
                        item.data = typeof data === "string" ? $.parseJSON(data) : data;
                        item.key = key;
                        items[key].push(item);
                        if (viewAllItemsSelector != null) {

                            viewAllItemsSelector.each(function () {
                                if (isItemAlreadyAdded($(this).val(), key)) {
                                    $(this).siblings("input").attr('checked', 'checked');
                                }
                            });
                        }
                    }
                }
            },
            removeItem = function (itemToRemove, event) {
                var parent = $(event.currentTarget).parent().parent().parent().parent();
                var key = parent.siblings("[name='UniqueListingKey']").val();

                //Should look into a uniform way of handling this.
                if (typeof key === 'undefined') {
                    //for cities
                    key = parent.find("[name='UniqueListingKey']").val();
                }
                if (typeof key === 'undefined') {
                    key = parent.parent().siblings("[name='UniqueListingKey']").val();
                }
                if (key == undefined) {
                    parent = parent.parent().parent();
                    key = parent.siblings("[name='UniqueListingKey']").val();
                }
                var viewAllSelector = parent.siblings("#list-view-all").find("input[id*='viewAllItem_Name_']").not("[data-isinherited='True']");
                remove(itemToRemove, key, viewAllSelector);
            },
            removeItemConfirm = function (itemToRemove, event) {
                if (confirm('Are you sure you want to remove this item?')) {
                    removeItem(itemToRemove, event);
                }
            },
            remove = function (itemToRemove, key, viewAllSelector) {
                var indexRemoved = itemToRemove.index;
                items[key].remove(itemToRemove);
                for (var i = indexRemoved; i < items[key]().length; i++) {
                    var currentItem = items[key]()[i];
                    currentItem.index = indexRemoved++;
                    $("[name='" + currentItem.inputName + "']").attr('name', wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + currentItem.index + ']');
                    currentItem.inputName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + currentItem.index + ']';
                }
                wdot.Listing.listingViewModel.CurrentIndex[key]--;
                viewAllSelector.each(function () {
                    if (isItemAlreadyAdded($(this).siblings("input").val(), key)) {
                        $(this).attr('checked', 'checked');
                    } else {
                        $(this).removeAttr('checked', 'checked');
                    }
                });

            },
            loadItems = function (json, key) {
                for (var prop in json) {
                    if (!json.hasOwnProperty(prop)) {
                        continue;
                    }
                    var id = json[prop]["Id"];
                    var isInhertied = json[prop]["IsInherited"];
                    if (!isInhertied) {
                        if (!isItemAlreadyAdded(id, key)) {
                            var item = new wdot.Listing.ListItem();
                            item.name = json[prop]["Name"];
                            item.isInherited = isInhertied;
                            item.id = id;
                            item.index = wdot.Listing.listingViewModel.CurrentIndex[key]++;
                            item.inputName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + item.index + ']';
                            item.inputNameForName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedName[' + item.index + ']';
                            item.data = json[prop]["Data"];
                            item.type = json[prop]["Type"];
                            item.key = key;
                            items[key].push(item);
                        }
                    } else {
                        var item = new wdot.Listing.ListItem();
                        item.name = json[prop]["Name"];
                        item.isInherited = isInhertied;
                        item.id = id;
                        item.data = json[prop]["Data"];
                        item.type = json[prop]["Type"];
                        item.key = key;
                        inheritedItems[key].push(item);
                    }
                }
            },
            loadInheritedItems = function (json, key, selector) {
                inheritedItems[key] = new Array();
                for (var prop in json) {
                    if (!json.hasOwnProperty(prop)) {
                        continue;
                    }
                    var id = json[prop]["Id"];
                    var isInhertied = json[prop]["IsInherited"];
                    var item = new wdot.Listing.ListItem();
                    item.name = json[prop]["Name"];
                    item.data = json[prop]["Data"];
                    item.type = json[prop]["Type"];
                    item.isInherited = isInhertied;
                    item.id = id;
                    item.key = key;
                    inheritedItems[key].push(item);
                }
                selector.find("#AllItemsList").find("input[id*='viewAllItem_Id_']").each(function () {
                    if (isItemAlreadyAdded($(this).val(), key)) {
                        $(this).siblings("input").attr('checked', 'checked');

                    } else if ($(this).siblings("input").attr("data-isinherited") == 'True' || isInherited($(this).val(), key)) {
                        $(this).siblings("input").attr('disabled', 'disabled');
                        $(this).siblings("input").attr('checked', 'checked');
                    } else {
                        $(this).siblings("input").removeAttr('checked');
                        $(this).siblings("input").removeAttr('disabled');
                    }
                });
            },
            loadViewAll = function (data, event) {
                debugger;
                if ($(event.currentTarget).next("#AllItemsList").length == 0) {
                    var itemId = $("#Id").val();
                    var key = $(event.currentTarget).attr("data-uniqueListingKey");
                    wdot.Listing.ViewAllModel.ViewAllItems = ko.observableArray();
                    Wdot.Ajax.prototype.AddAjaxLoader($('body'), true);
                    var url = $(event.currentTarget).attr("data-viewAllMethod");
                    var splitUrl = url.split('?');
                    var queryString = '';
                    if (splitUrl.length > 1) {
                        queryString = splitUrl[1];
                    }
                    Wdot.Ajax.prototype.GetWithOutCache(splitUrl[0] + "/" + itemId + "?" + queryString, { key: key, title: $(event.currentTarget).parent().attr("title"), ShowCityGroupAsCity: $("[Name='ShowCityGroupAsCity']").is(":checked") }, function (data, status, xhr) {
                        $(event.currentTarget).parent().children("#AllItemsList").remove();
                        Wdot.Ajax.prototype.RemoveAjaxLoader($('body'), true);
                        $(event.currentTarget).parent().append(data);
                        $(event.currentTarget).next().children("#AllItemsList").find("button").click(addItemsFromViewAll);
                        $(event.currentTarget).parent().children("#list-view-all").dialog({ resizable: false, modal: true, height: 500, width: 550, close: function () {
                            $(this).dialog('destroy').remove();
                        }
                        });
                    });
                }
            },
            closeViewAll = function (data, event) {
                $(event.currentTarget).prev("#LoadViewAll").removeClass("hidden");
                $(event.currentTarget).addClass("hidden");
                $(event.currentTarget).next("#AllItemsList").addClass("hidden");
            },
            addItemsFromViewAllGeneric = function (selector, keepDialogOpen) {
                var key = selector.parent().attr("data-uniquelistingkey");
                var checkedItems = selector.parent().find("input[type='checkbox']:checked").not("[data-isinherited='True']");
                var notCheckedItems = selector.parent().find("input[type='checkbox']").not(':checked');
                if (wdot.Listing.listingViewModel.MaxItems[key] == 0 || checkedItems.length + items[key]().length <= wdot.Listing.listingViewModel.MaxItems[key]) {
                    checkedItems.each(function () {
                        var id = $(this).next("input").val();
                        if (!isItemAlreadyAdded(id, key)) {
                            var item = new wdot.Listing.ListItem();
                            item.name = $(this).val();
                            item.id = id;
                            item.type = $(this).attr('data-type');
                            item.index = wdot.Listing.listingViewModel.CurrentIndex[key]++;
                            item.inputName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + item.index + ']';
                            item.inputNameForName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedName[' + item.index + ']';
                            item.data = $.parseJSON($(this).attr("data-listItemData"));
                            item.key = key;
                            items[key].push(item);
                            $(this).attr('checked', 'checked');
                        }
                    });
                    notCheckedItems.each(function () {
                        var id = $(this).next("input").val();
                        if (isItemAlreadyAdded(id, key)) {
                            removeItemById($(this).siblings("input").val(), key);
                        }
                    });
                } else {
                    if (checkedItems.length > wdot.Listing.listingViewModel.MaxItems[key]) {
                        selector.after("<span class='field-validation-error'>You can only select up to " + wdot.Listing.listingViewModel.MaxItems[key] + " items.</span>");
                    }
                }

                if (!keepDialogOpen)
                    selector.parent().parent().dialog('close');
            },
            addItemsFromViewAll = function (event) {
                addItemsFromViewAllGeneric($(event.currentTarget), false);
            },
            isItemAlreadyAdded = function (id, key) {
                for (var i = 0; i < items[key]().length; i++) {
                    if (items[key]()[i].id == id) {
                        return true;
                    }
                }
                return false;
            },
            removeItemById = function (id, key) {
                var indexRemoved = 0;
                for (var x = 0; x < items[key]().length; x++) {
                    if (items[key]()[x].id == id) {
                        items[key].remove(items[key]()[x]);
                        indexRemoved = x;
                    }
                }
                for (var i = indexRemoved; i < items[key]().length; i++) {
                    var currentItem = items[key]()[i];
                    currentItem.index = indexRemoved++;
                    $("[name='" + currentItem.inputName + "']").attr('name', wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + currentItem.index + ']');
                    currentItem.inputName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + currentItem.index + ']';
                }
                wdot.Listing.listingViewModel.CurrentIndex[key]--;
            },
            updateIndex = function (event, ui, key) {
                var oldArray = items[key]();
                var oldIndex = ui.item.children("input").attr("name").substring(ui.item.children("input").attr("name").indexOf('[') + 1).replace(']', '');

                var newIndex = ui.item.index();

                var item = oldArray.splice(oldIndex, 1)[0];
                oldArray.splice(newIndex, 0, item);

                for (var x = 0; x < oldArray.length; x++) {
                    oldArray[x].index = x;
                    $("[name='" + oldArray[x].inputName + "'][value='" + oldArray[x].id + "']").attr('name', wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']');
                    oldArray[x].inputName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']';
                }
            },
            updateDisplayOrder = function (arg, event, ui) {
                var key = arg.item.key;
                var oldArray = items[key]();
                var oldIndex = arg.sourceIndex;

                var newIndex = arg.targetIndex;

                var item = oldArray.splice(oldIndex, 1)[0];
                oldArray.splice(newIndex, 0, item);

                for (var x = 0; x < oldArray.length; x++) {
                    oldArray[x].index = x;
                    $("[name='" + oldArray[x].inputName + "'][value='" + oldArray[x].id + "']").attr('name', wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']');
                    oldArray[x].inputName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']';
                    $("[name='" + oldArray[x].inputNameForName + "'][value='" + oldArray[x].name + "']").attr('name', wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']');
                    oldArray[x].inputNameForName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']';
                }
            },
            updateDisplayOrderForMediaInAlbum = function (arg, event, ui) {
                var key = arg.item.key;
                var oldArray = items[key]();

                for (var x = 0; x < oldArray.length; x++) {
                    oldArray[x].index = x;
                    $("[name='" + oldArray[x].inputName + "'][value='" + oldArray[x].id + "']").attr('name', wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']');
                    oldArray[x].inputName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']';
                    $("[name='" + oldArray[x].inputNameForName + "'][value='" + oldArray[x].name + "']").attr('name', wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']');
                    oldArray[x].inputNameForName = wdot.Listing.listingViewModel.MVCListBindingName[key] + '.SelectedItems[' + oldArray[x].index + ']';
                }
            },
            isInherited = function (id, key) {
                var isInheritedBool = false;
                $(wdot.Listing.listingViewModel.InheritedItems[key]).each(function () {
                    if (this.id == id) {
                        isInheritedBool = true;
                    }
                });
                return isInheritedBool;
            };
        return {
            Items: items,
            AddItem: addItem,
            AddMediaGalleryItem: addMediaGalleryItem,
            RemoveItem: removeItem,
            RemoveItemConfirm: removeItemConfirm,
            LoadItems: loadItems,
            MaxItems: maxItems,
            UnlimitedItems: unlimitedItems,
            InitListing: initListing,
            MVCListBindingName: mvcListBindingName,
            LoadViewAll: loadViewAll,
            CloseViewAll: closeViewAll,
            AddItemsFromViewAllGeneric: addItemsFromViewAllGeneric,
            AddItemsFromViewAll: addItemsFromViewAll,
            InheritedItems: inheritedItems,
            UpdateIndex: updateIndex,
            UpdateDisplayOrderForMediaInAlbum: updateDisplayOrderForMediaInAlbum,
            LoadInheritedItems: loadInheritedItems,
            SubscribeToListingItems: subscribeToListingItems,
            Add: add,
            Remove: remove,
            UpdateDisplayOrder: updateDisplayOrder
        };
    } ();
    return {
        listingViewModel: listingViewModel,
        ListItem: listItem,
        ViewAllModel: viewAllModel
    };
} ();