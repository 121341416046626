var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Wdot;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        //console.log('wdot/bingmaps/SinglePinMapWithInfobox.js wdot.maps loaded'); 
        var SinglePinMapWithInfobox = (function (_super) {
            __extends(SinglePinMapWithInfobox, _super);
            function SinglePinMapWithInfobox(key) {
                //console.log('wdot/bingmaps/SinglePinMapWithInfobox.js SinglePinMapWithInfobox()'); 
                var _this = _super.call(this, key) || this;
                _this.map = null;
                _this.websiteUrl = "";
                _this.panningHandlerId = 0;
                _this.mapElement = null;
                _this.latitude = null;
                _this.longitude = null;
                _this.isCity = null;
                _this.disableMapPanning = function (mapDisabled) {
                    if (mapDisabled) {
                        this.panningHandlerId = Microsoft.Maps.Events.addHandler(this.map, 'mousedown', function (mouseEvent) {
                            if (this.isMobile) {
                                mouseEvent.handled = true;
                            }
                        });
                    }
                    else {
                        if (this.panningHandlerId) {
                            this.panningHandlerId = Microsoft.Maps.Events.removeHandler(this.panningHandlerId);
                        }
                    }
                };
                return _this;
            }
            SinglePinMapWithInfobox.prototype.loadSinglePinMapWithInfobox = function (pinObject, zoomLevel, mapElement, websiteUrl, isCity) {
                //console.log('wdot/bingmaps/SinglePinMapWithInfobox.js loadSinglePinMapWithInfobox()');
                if (isCity) {
                    this.isCity = isCity;
                }
                if (mapElement) {
                    this.mapElement = mapElement;
                }
                if (websiteUrl) {
                    this.websiteUrl = websiteUrl;
                }
                if (pinObject) {
                    this.latitude = pinObject[0].Latitude;
                    this.longitude = pinObject[0].Longitude;
                }
                var center = new Microsoft.Maps.Location(this.latitude, this.longitude);
                this.map = new Microsoft.Maps.Map(this.mapElement, {
                    enableClickableLogo: false,
                    showMapTypeSelector: false,
                    showDashboard: true,
                    showBreadcrumb: false,
                    credentials: this.apiKey,
                    zoom: zoomLevel,
                    center: center,
                    disableScrollWheelZoom: true,
                    showZoomButtons: this.showZoomControls
                });
                this.map.getZoomRange = function () {
                    return { max: 15, min: 5 };
                };
                var self = this;
                Microsoft.Maps.Events.addHandler(self.map, 'viewchangeend', function () {
                    if (self.map.getZoom() <= self.map.getZoomRange().min) {
                        self.map.setView({ 'zoom': self.map.getZoomRange().min });
                    }
                    else if (self.map.getZoom() >= self.map.getZoomRange().max) {
                        self.map.setView({ 'zoom': self.map.getZoomRange().max });
                    }
                });
                Microsoft.Maps.Events.addHandler(this.map, 'mousewheel', function (e) {
                    return true;
                });
                this.addLocation(pinObject[0]);
                if (zoomLevel != undefined && zoomLevel != null) {
                    this.map.setView({
                        center: center,
                        zoom: zoomLevel
                    });
                }
                else {
                    this.bestMapView($("#singlePin .MicrosoftMap").width(), $("#singlePin .MicrosoftMap").height(), 75);
                }
            };
            SinglePinMapWithInfobox.prototype.addLocation = function (item) {
                //console.log('wdot/bingmaps/SinglePinMapWithInfobox.js addLocation()');
                var offset = new Microsoft.Maps.Point(0, 5);
                var pinOptions = {
                    visible: true,
                    textOffset: offset,
                    draggable: false,
                    anchor: new Microsoft.Maps.Point(14, 14)
                };
                var infoboxHtmlString = null;
                if (this.isCity) {
                    infoboxHtmlString = Maps.BingMapsHelpers.getInfoboxHtmlString(item.City, item.City, null, null, false, 0);
                }
                else {
                    infoboxHtmlString = Maps.BingMapsHelpers.getInfoboxHtmlString(item.PlaceName, item.Address, item.Address, this.websiteUrl, false, 0);
                }
                var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(item.Latitude, item.Longitude), pinOptions);
                this.map.entities.push(pin);
                var infoboxOptions = {
                    showCloseButton: false,
                    htmlContent: infoboxHtmlString,
                    visible: true,
                };
                var infoBox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(this.latitude, this.longitude), infoboxOptions);
                infoBox.setMap(this.map);
                return pin;
            };
            SinglePinMapWithInfobox.prototype.ResetMapCenter = function () {
                this.map.setView({ center: new Microsoft.Maps.Location(this.latitude, this.longitude) });
            };
            return SinglePinMapWithInfobox;
        }(Maps.BingMaps));
        Maps.SinglePinMapWithInfobox = SinglePinMapWithInfobox;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));