(function (wdot) {
    (function(MediaUploaderCollection) {
        var MediaUploader = (function(fileUploaderContainer /*jQueryObject*/) {
            var self = this;
            var files = fileUploaderContainer.data('mu_currentmedia'),
                minFiles = fileUploaderContainer.data('mu_minfiles'),
                maxFiles = fileUploaderContainer.data('mu_maxfiles'),
                minRequiredHeight = fileUploaderContainer.data('mu_minrequiredheight'),
                minRequiredWidth = fileUploaderContainer.data('mu_minrequiredwidth');
            if (files.length > 0) {
                self.mediaFiles = ko.observableArray(files);
            } else {
                self.mediaFiles = ko.observableArray([]);
            }
            self.mediaFilesJson = ko.observableArray();
            self.setMediaFiles = function(arrMediaFiles) {
                if (arrMediaFiles == null)
                    arrMediaFiles = [];

                self.mediaFiles(arrMediaFiles);
            };
            self.numberOfFiles = ko.computed(function() {
                if (self.mediaFiles != undefined && self.mediaFiles() != undefined)
                    return self.mediaFiles().length;

                return 0;
            });
            self.addMediaFile = function (id, fileName, mediaFile) {
                var file = ko.observable(mediaFile);
                file().DisplayName = fileName;
                file().Caption = "";
                file().PhotoCredit = "";
                file().Id = 0;
                self.mediaFiles.unshift(file());
                self.updateMediaFiles();
            };
            self.removeMediaFile = function() {
                var mediaFile = this;
                self.mediaFiles.remove(mediaFile);
                fileUploaderContainer.find('.upload-message').html('');
                self.updateMediaFiles();
            };
            self.cleanUrl = function(url) {
                if (url != null && url != '') {
                    return url.replace('~', '');
                } else {
                    return '';
                }
            };
            self.popMediaFiles = function() {
                self.mediaFiles.pop();
            };
            self.updateMediaFiles = function() {
                if (self.mediaFiles().length === 0) {
                    self.mediaFilesJson('');
                }
                self.mediaFilesJson(JSON.stringify(self.mediaFiles()));
            };
            //console.log('MediaUploader.js.self.fileUploader');
            self.fileUploader = new qq.FileUploader({
                element: fileUploaderContainer.find('.MediaUploader')[0],
                action: fileUploaderContainer.data('mu_action'),
                params: { ModuleName: fileUploaderContainer.data('mu_modulename'), AllowedTypes: fileUploaderContainer.data('mu_allowedtypes') },
                debug: true,
                allowedExtensions: fileUploaderContainer.data('mu_allowedtypesarray'),
                multiple: fileUploaderContainer.data('mu_allowmultiple'),
                onSubmit: function(id, fileName) {
                    fileUploaderContainer.find('.upload-message').html('Uploading...');
                },
                onProgress: function(id, fileName, loaded, total) {
                    fileUploaderContainer.find('.upload-message').html('Uploading...');
                },
                onCancel: function(id, fileName) {
                    fileUploaderContainer.find('.upload-message').html('');
                },
                onComplete: function (id, fileName, responseJSON) {
                    fileUploaderContainer.find('.upload-message').html('Upload complete');
                    if ((responseJSON.Success == true) && (responseJSON.DimensionsCorrect == true)) {
                        if (self.numberOfFiles() >= maxFiles) {
                            self.popMediaFiles();
                        }
                        self.addMediaFile(id, fileName, responseJSON);
                    } else {
                        if ((responseJSON.Success == true) && (responseJSON.DimensionsCorrect == false) && (responseJSON.CheckRequired == true)) {
                            if (responseJSON.ContentType == "Places" || responseJSON.ContentType == "Events") {
                                fileUploaderContainer.find('.upload-message').html('You selected an image that does not meet the 800px wide by 400px high requirement.');
                            } else {
                                fileUploaderContainer.find('.upload-message').html('You selected an image that does not meet the 1440px wide by 473px high requirement.');
                            }
                        } else {
                            fileUploaderContainer.find('.upload-message').html('Upload failed.');
                        }
                    }
                },
                messages: {
                    typeError: "{file} has invalid extension. Only {extensions} are allowed.",
                    sizeError: "{file} is too large, maximum file size is {sizeLimit}.",
                    minSizeError: "{file} is too small, minimum file size is {minSizeLimit}.",
                    emptyError: "{file} is empty, please select files again without it.",
                    onLeave: "The files are being uploaded, if you leave now the upload will be cancelled."
                },
                showMessage: function(message) {
                    alert(message);
                }
            });

            self.mediaFiles.subscribe(function() {
                self.updateMediaFiles();
            });

            self.updateMediaFiles();
            ko.applyBindings(self, fileUploaderContainer[0]);
            return self;
        }),
        mediaUploaderCollection = [],
        populateMediaUploader = function(container) {
            // console.log('MediaUploader.js populateMediaUploader.container:', container);
            // console.log('MediaUploader.js populateMediaUploader.container.len:', container.length);
            container.each(function() {
                mediaUploaderCollection.push(new MediaUploader($(this)));
            });
        },
        getNumberOfFilesByIndex = function (index) {
            return mediaUploaderCollection[index].numberOfFiles();
        };
            
        //console.log('MediaUploader.js delay populateMediaUploader');
        //need a delay to make sure the DOM has finished loading
        setTimeout(() => {
            populateMediaUploader($(".MediaUploaderModule"));
        }, 500);

        MediaUploaderCollection.GetNumberOfFilesByIndex = getNumberOfFilesByIndex;
    })(wdot.MediaUploaderCollection || (wdot.MediaUploaderCollection = {}));
})(wdot || (wdot = {}));

//console.log('MediaUploader.js loaded');