var Wdot;
(function (Wdot) {
    var Maps;
    (function (Maps) {
        var Polygons = (function () {
            function Polygons(coordinates) {
                this.coordinates = coordinates;
            }
            return Polygons;
        }());
        Maps.Polygons = Polygons;
    })(Maps = Wdot.Maps || (Wdot.Maps = {}));
})(Wdot || (Wdot = {}));